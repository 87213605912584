import { get } from '../index';
import getAsset from '../assets/getAsset';

async function getProduct(id) {
  try {
    const product = await get('products', id);
    return {
      ...product,
      id,
    };
  } catch (err) {
    console.log('Error Get Product: ', err);
  }
}

export default getProduct;
