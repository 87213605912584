const sources = {
  facebook: /(.*?)facebook.com(.*?)/,
  instagram: /(.*?)instagram.com(.*?)/,
  google: /(.*?)google.com(.*?)/,
  twitter: /(.*?)twitter.com(.*?)/,
  tiktok: /(.*?)tiktok.com(.*?)/,
  linkedin: /(.*?)linkedin.com(.*?)/,
  nextdoor: /(.*?)nextdoor.com(.*?)/,
  castiron: /(.*?)castiron.me(.*?)/,
  dev: /(.*?)localhost(.*?)/,
  qa: /(.*?)firebaseapp.com(.*?)/,
  direct: null,
  unknown: null,
};

export type TrafficSource = keyof typeof sources;

export interface Attribution {
  source?: TrafficSource;
  campaignId?: string;
  referringSourceUrl?: string;
}

const realSources = Object.fromEntries(Object.entries(sources).filter(e => e[1] !== null));

export const getSource = (referrer: string): TrafficSource => {
  const sourceEntry = Object.entries(realSources).find(e => e[1].test(referrer));
  return (sourceEntry ? sourceEntry[0] : 'unknown') as TrafficSource;
};
