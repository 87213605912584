import React, { ReactNode } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

export type PresetFonts = 'txt--xs' | 'txt--s' | 'txt--m' | 'txt--l';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type TextProps = Override<
  TypographyProps,
  {
    fontSize?: number;
    bold?: boolean;
    fontWeight?: number;
    lineHeight?: number;
    color?: string;
    children?: ReactNode;
    presetFont?: PresetFonts;
    uppercase?: boolean;
  }
>;

const useStyles = makeStyles((theme: Theme) => ({
  'txt--xs': { fontSize: 12, lineHeight: '24px', fontWeight: 400 },
  'txt--s': { fontSize: 14, lineHeight: '24px', fontWeight: 400 },
  'txt--m': { fontSize: 16, lineHeight: '24px', fontWeight: 400 },
  'txt--l': { fontSize: 18, lineHeight: '24px', fontWeight: 400 },
}));

const Text: React.FC<TextProps> = (props: TextProps) => {
  const {
    presetFont,
    fontSize,
    bold,
    lineHeight,
    color,
    fontWeight,
    className,
    style,
    uppercase,
    ...restOfProps
  } = props;
  const classes = useStyles();

  return (
    <Typography
      className={`${presetFont ? classes[presetFont] : ''} ${className ? className : ''}`}
      style={{
        ...style,
        fontSize,
        lineHeight: lineHeight ? `${lineHeight}px` : undefined,
        fontWeight: fontWeight ? fontWeight : bold ? 700 : undefined,
        color,
        textTransform: uppercase ? 'uppercase' : undefined,
      }}
      {...restOfProps}
    >
      {props.children}
    </Typography>
  );
};

export default Text;
