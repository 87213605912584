import { get } from '../index';

async function getAsset(id) {
  try {
    const asset = await get('assets', id);
    return {
      ...asset,
      id,
    };
  } catch (err) {
    console.log('Error Get Asset: ', err);
  }
}

export default getAsset;
