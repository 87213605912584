import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { accounts } from '@castiron/castiron-firebase';
import { Account } from '../../models/Account';

export interface AccountState {
  accounts: Account[];
  account: Account;
  loading: boolean;
  error: string;
}

const initialState: AccountState = {
  accounts: [],
  account: null,
  loading: false,
  error: '',
};

//eslint-disable-next-line
const getAccount = async (id: string) => {
  const response = await accounts.get(id);
  console.log('response from getaccount: ', response);
  return response;
  // return {
  //   id: '1',
  //   businessName: 'test',
  //   phone: 'test',
  //   address: 'test',
  //   addressTwo: 'test',
  //   category: 'test',
  //   websiteURL: 'test',
  //   passwordProtection: 'test',
  //   password: 'test',
  //   message: 'test',
  // };
};

//eslint-disable-next-line
const createAccount = async (account: any) => {
  //@ts-ignore
  const response = await accounts.create(account);
  console.log('response from createAccount: ', response);
  //@ts-ignore
  return response.data;
};

//eslint-disable-next-line
// const removeAccount = async (id: string) => {
//   const response = await accountModel.remove(id);
//   console.log('response from removeAccount: ', response);
//   return response.data;
// };

// //eslint-disable-next-line
const updateAccount = async (account: any = {}) => {
  const response = await accounts.update(account);
  console.log('response from  updateAccount: ', response);
  //@ts-ignore
  return response.data;
};

export const getAccountAction = createAsyncThunk('accounts/getAccount', getAccount);
export const createAccountAction = createAsyncThunk('accounts/createAccount', createAccount);
export const updateAccountAction = createAsyncThunk('accounts/updateAccount', updateAccount);
// export const removeAccountAction = createAsyncThunk('accounts/removeAccount', removeAccount);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // you can mutate state directly, since it is using immer behind the scenes\
    builder
      .addCase(getAccountAction.pending, state => {
        state.loading = true;
      })
      .addCase(getAccountAction.fulfilled, (state, action) => {
        //@ts-ignore
        state.account = action.payload;
        state.loading = false;
      })
      .addCase(createAccountAction.pending, state => {
        state.loading = true;
      })
      .addCase(createAccountAction.fulfilled, (state, action) => {
        state.account = action.payload;
        state.loading = false;
      })
      .addCase(updateAccountAction.pending, state => {
        state.loading = true;
      })
      .addCase(updateAccountAction.fulfilled, (state, action) => {
        state.account = action.payload;
        state.loading = false;
      });
  },
});

export default accountSlice.reducer;
