import { ButtonBase, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import Popover from '@castiron/components/src/Popover/Popover';
import ReceiptIcon from '../../../assets/img/receipt.svg';
import CloseIcon from '../../../assets/img/close.svg';
import SvgIcon from '@castiron/components/src/SvgIcon';
import { useHistory } from 'react-router';

type Props = {
  anchorEl: Element;
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    minWidth: 200,
    paddingLeft: 8,
    paddingRight: 8,
  },
  icon: {
    color: theme.branding.callout,
    paddingRight: 8,
    width: 28,
    height: 28,
  },
  text: {
    fontSize: 14,
  },
  title: {
    fontWeight: 700,
    paddingBottom: 12,
  },
  buttonBase: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    height: 45,
    paddingRight: 12,
    '&:hover': {
      backgroundColor: theme.branding.gray[600],
    },
  },
}));

const NotificationsPopover: React.FC<Props> = (props: Props) => {
  const { anchorEl, onClose } = props;
  const classes = useStyles();
  const history = useHistory();

  const onNotificationClick = (route: string): void => {
    history.push(route);
    onClose();
  };

  const notifications = [
    {
      id: 1,
      icon: <ReceiptIcon />,
      title: '16 new orders',
      onClick: (): void => onNotificationClick('/orders'),
    },
    {
      id: 2,
      icon: <CloseIcon />,
      title: '1 product out-of-stock',
      onClick: (): void => onNotificationClick('/products'),
    },
  ];

  return (
    <Popover paperClass={classes.paper} anchorEl={anchorEl} onClose={onClose}>
      <Typography className={`${classes.text} ${classes.title}`}>2 new notifications</Typography>
      {notifications.map(notification => (
        <ButtonBase focusRipple onClick={notification.onClick} className={classes.buttonBase} key={notification.id}>
          <SvgIcon className={classes.icon}>{notification.icon}</SvgIcon>
          <Typography className={classes.text}>{notification.title}</Typography>
        </ButtonBase>
      ))}
    </Popover>
  );
};

export default NotificationsPopover;
