import React, { ReactChild } from 'react';
import { Grid, GridProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Text from '../Text';

const useStyles = makeStyles((theme: Theme) => ({}));

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

type Props = Override<
  GridProps,
  {
    label: string | ReactChild;
    labelSize?: number;
    labelBold?: boolean;
    value?: string | ReactChild;
    valueSize?: number;
    valueBold?: boolean;
  }
>;

const LabelValue: React.FC<Props> = (props: Props) => {
  const { label, value, labelSize, labelBold, valueSize, valueBold, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <Grid container justify="space-between" {...restOfProps}>
      {typeof label === 'string' ? (
        <Text presetFont="txt--s" fontSize={labelSize} bold={labelBold}>
          {label}
        </Text>
      ) : (
        label
      )}
      {typeof value === 'string' ? (
        <Text presetFont="txt--s" fontSize={valueSize} bold={valueBold}>
          {value || ''}
        </Text>
      ) : (
        value
      )}
    </Grid>
  );
};

export default LabelValue;
