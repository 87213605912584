import React, {ReactNode} from "react";
import {Grid, makeStyles, Theme} from "@material-ui/core";
import {Button} from "../Button";

interface Props {
  className?: string;
  children: ReactNode;
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  content:{
    width: '100%',
    borderRadius: 8,
    textAlign: 'left',
    alignItems: 'unset',
    padding: '24px',
    color: theme.branding.castiron,
    '&:hover': {
      background: theme.branding.ghostWhite,
    }
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: 'unset',
      borderRadius: 0,
    },
  },
  innerContainer: {
    width: '100%'
  }
}));
const ResultCard: React.FC<Props> = (props:Props) => {
  const classes = useStyles();

  const className = `${props.className ? props.className : ''} ${classes.content}`

  return (
    <Grid item className={classes.container} xs={12} sm={6} lg={4}>
      <Button
        variant="outlined"
        className={className}
        onClick={props.onClick}
      >
        <Grid container direction="column">
          {props.children}
        </Grid>
      </Button>
    </Grid>
  );
};

export default ResultCard;
