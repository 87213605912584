'use strict';

class Confetti {
  constructor(options = {}) {
    let particleCount = options.particleCount ? options.particleCount : 40;
    let startVelocity = options.startVelocity ? options.startVelocity : 20;
    let spread = options.spread ? options.spread : 90;
    if (window.confetti) {
      window.confetti({
        particleCount: particleCount,
        startVelocity: startVelocity,
        spread: spread,
      });
    }
  }
}

export default Confetti;
