import firebase from 'firebase';
import _firestore from '@google-cloud/firestore';
import _ from 'lodash';
import { BaseDocument, BaseRepository, Condition, FieldFunctions, Order } from '../base/repository';
import { Asset } from '../asset/model';
import { Address } from '../address';
import {TakeRateLevel} from "../account";

export enum ChecklistValues {
  StoreName = 'STORE_NAME',
  SignUpPage = 'SIGN_UP_PAGE',
  ProductAdded = 'PRODUCT_ADDED',
  FulfillmentAdded = 'FULFILLMENT_ADDED',
  ProfilePhoto = 'PROFILE_PHOTO',
  AboutInfo = 'ABOUT_INFO',
  Logo = 'LOGO',
  SocialLinks = 'SOCIAL_LINKS',
  AllergenInfo = 'ALLERGEN_INFO',
  StripeConnection = 'STRIPE_CONNECTION',
  GoLive = 'GO_LIVE',
  CustomerList = 'CUSTOMER_LIST',
  EmailMarketing = 'EMAIL_MARKETING',
  CouponCreate = 'COUPON_CREATE',
}

export interface Category {
  id: string;
  name: string;
}

export interface SubCategory {
  name: string;
}

export type FulfillmentType = 'pickup' | 'delivery' | 'shipping' | 'custom';
export type Allergens =
  | 'wheat'
  | 'milk'
  | 'eggs'
  | 'fish'
  | 'shellfish'
  | 'treeNuts'
  | 'peanuts'
  | 'soyBeans'
  | 'sesame';

export interface FulfillmentOption {
  id: string;
  active: boolean;
  type: FulfillmentType;
  displayName: string;
  description: string;
  afterPurchaseDetails?: string;
  postalCode?: string;
  // TODO: Remove this. It is really in the ShippingInfo
  recipient?: string;
  fee?: number;
  minimum?: number;
  // TODO: Remove this. It is really in the ShippingInfo
  address?: Address;
  date?: number;
  fulfillmentNotes?: string;
  notes?: string;
}

export interface SocialMediaInfo {
  facebookLink?: string;
  instagramLink?: string;
}

export interface Owner {
  firstName: string;
  lastName: string;
}

export interface PaymentSettings {
  takeRateLevels?: TakeRateLevel[];
  castironTakeRate: number;
  customerRate: number;
  isCustomerPayingStripeFee: boolean;
  taxRate?: number;
}
export interface Banner {
  enabled: boolean;
  message: string;
}

export interface ShopConfig {
  banner?: Banner;
}

export type MadeBadges = 'home' | 'commercial';
export type CertificationBadges = 'licensed-cotttage' | 'licensed-establishment' | 'certified-food-handler';
export type SpecialDietsBadges = 'gluten-free' | 'allergen-friendly' | 'plant-based';
export type MoreBadges = 'minority-owned' | 'woman-owned';

export interface Badges {
  made?: MadeBadges;
  certifications: CertificationBadges[];
  specialDiets: SpecialDietsBadges[];
  more: MoreBadges[];
}

export interface ArtisanCategory {
  name: string;
  subcategories: string[];
}

export interface EditModeConfig {
  productFilterBy?: string;
}

export interface ShopSummary {
  id: string;
  businessName: string;
  owner: Owner;
  websiteUrl: string;
  logoImageObj?: Asset;
  profileImageObj?: Asset;
  artisanCategory?: ArtisanCategory;
  fulfillmentTypes?: FulfillmentType[];
  location?: Address;
}

export interface Shop extends BaseDocument<Shop> {
  status?: 'active' | 'inactive' | 'deleted' | 'prelaunch';
  owner?: Owner;
  websiteUrl: string;
  aboutTitle: string;
  allergens?: Allergens[];
  businessName: string;
  phoneNumber?: string;
  castIronCoverImage?: string;
  castIronCoverImageObj?: Asset;
  coverImage?: string;
  coverImageObj?: Asset;
  description?: string;
  email: string;
  socialMedia?: SocialMediaInfo;
  fulfillment?: FulfillmentOption[];
  fulfillmentNotes?: string;
  logo?: string;
  logoImageObj?: Asset;
  profileImage?: string;
  profileImageObj?: Asset;
  useLogoAndName?: boolean;
  categories?: Category[];
  physicalAddress?: Address;
  billingAddress?: Address;
  canAcceptPayments?: boolean;
  productTypes?: string[];
  checklistCompletions?: ChecklistValues[];
  hasEverLaunched?: boolean;
  paymentSettings: PaymentSettings;
  config?: ShopConfig;
  badges?: Badges;
  artisanCategory?: ArtisanCategory;
  tags?: string[];
  editModeConfig?: EditModeConfig;
}

export const toShopSummary = (s: Shop) => ({
  id: s.id,
  businessName: s.businessName,
  websiteUrl: s.websiteUrl,
  owner: s.owner,
  logoImageObj: s.logoImageObj,
  profileImageObj: s.profileImageObj,
  artisanCategory: s.artisanCategory,
  fulfillmentTypes: _.uniq(s.fulfillment.map(f => f.type)),
  location: s.physicalAddress,
});

export interface ArtisanActiveShopsSearchLocation {
  region?: string;
  city?: string;
}

export interface ArtisanActiveShopsSearch {
  artisanCategories?: string[];
  fulfillment?: string[];
  region?: string[];
}

export class ShopRepository extends BaseRepository<Shop> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'shops', fieldFunctions);
  }

  public async findByWebsiteUrl(websiteUrl: string): Promise<Shop | null> {
    const result = await this.find({
      where: [
        { field: 'status', operator: 'in', value: ['active', 'prelaunch'] },
        { field: 'websiteUrl', operator: '==', value: websiteUrl },
      ],
    });
    return this.firstOrNull(result);
  }

  public async findWebsiteUrlsStartsWith(startWith: string): Promise<Shop[]> {
    return this.findFieldStartsWith('websiteUrl', startWith, [
      { field: 'status', operator: 'in', value: ['active', 'inactive', 'prelaunch'] },
    ]);
  }

  public async findArtisanActiveShops(search: ArtisanActiveShopsSearch): Promise<Shop[]> {
    const where: Condition<Shop>[] = [{ field: 'status', operator: '==', value: 'active' }];

    if (search.region && search.region.length > 0) {
      where.push({ field: 'physicalAddress.region', operator: 'in', value: search.region });
    } else if (search.artisanCategories && search.artisanCategories.length > 0) {
      where.push({ field: 'artisanCategory.name', operator: 'in', value: search.artisanCategories });
    }

    let results = await this.find({
      where,
      orderBy: [{ field: 'businessName', direction: 'asc' }],
    });

    if (search.artisanCategories && search.artisanCategories.length > 0 && search.region && search.region.length > 0) {
      results = results.filter(s => search.artisanCategories.includes(s.artisanCategory?.name));
    }

    if (search.fulfillment && search.fulfillment.length > 0) {
      results = results.filter(s => s.fulfillment?.map(f => f.type).some(f => search.fulfillment.includes(f)));
    }

    return results;
  }

  public async addTags(shop: Shop, tags: string[]): Promise<Shop> {
    await this.updateProps(shop.id, {
      tags: this.fieldFunctions.arrayUnion(tags),
    });
    return {
      ...shop,
      tags: [...shop.tags, ...tags],
    };
  }

  public async removeTags(shop: Shop, tags: string[]): Promise<Shop> {
    await this.updateProps(shop.id, {
      tags: this.fieldFunctions.arrayRemove(tags),
    });
    return {
      ...shop,
      tags: shop.tags.filter(t => !tags.includes(t)),
    };
  }
}

export interface ShopRelatedDocument<T> extends BaseDocument<T> {
  shopId: string;
}

export class ShopRelatedRepository<T extends ShopRelatedDocument<T>> extends BaseRepository<T> {
  constructor(
    firestore: firebase.firestore.Firestore | _firestore.Firestore,
    collectionName: string,
    fieldFunctions?: FieldFunctions,
  ) {
    super(firestore, collectionName, fieldFunctions);
  }

  public async findByShopId(shopId: string, orderBy: Order<T>[] = []): Promise<T[]> {
    return await this.find({
      where: [this.whereShopIs(shopId), { field: 'status', operator: '!=', value: 'deleted' }],
      orderBy,
    });
  }

  protected whereShopIs(shopId: string): Condition<T> {
    return { field: 'shopId', operator: '==', value: shopId };
  }
}
