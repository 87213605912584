import React, { ReactElement, useState } from 'react';
import { Box, IconButton, MenuItem, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppSelector, useAppDispatch } from '../../hooks';
import ModalWrapper from '../RootModal/ModalWrapper';
import { Input } from '@castiron/components';
import { Button } from '@castiron/components';
import { closeModal } from '../../store/reducers/modalConductor';
import { Close } from '@material-ui/icons';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import firebase from 'firebase';
import { Forms } from '@castiron/components';

type Props = {
  show: boolean;
  email?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 14,
  },
  container: {
    [theme.breakpoints.up('md')]: {
      padding: '31px 48px 37px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px 16px',
    },
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
    marginBottom: 17,
  },
  tabPanel: {
    padding: 0,
  },
  row: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    justifyContent: 'flex-start',
  },
  rowItem: {
    flexGrow: 1,
  },
  inputRow: {
    [theme.breakpoints.up('md')]: {
      gap: 20,
      alignItems: 'flex-end',
    },
  },
  checkbox: {
    flexGrow: 0.5,
    [theme.breakpoints.up('md')]: {
      marginBottom: 24,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
    },
  },
  checkboxEmailContainer: {
    position: 'relative',
    flexGrow: 0.5,
  },
  checkboxInput: {
    marginTop: 12,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 20,
    marginTop: 17,
  },
  labelText: {
    fontWeight: 600,
    fontSize: 14,
  },
  secondaryLabel: {
    fontWeight: 400,
    color: theme.branding.graphite,
  },
  inputContainer: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 0.5,
    },
  },
  selectContainer: {
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
}));

const contactTemplates = ['Blank template'];

const functions = firebase && firebase.functions ? firebase.functions() : null;

const ContactModal: React.FC<Props> = (props: Props) => {
  const { show, email } = props;
  const [tab, setTab] = useState('email');
  // const [sendToMe, setSendToMe] = useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { shop, me } = useAppSelector(state => ({
    shop: state.shops.shop,
    me: state.users.me,
  }));

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  const onTabChange = (event, selectedTab: 'email' | 'sms'): void => {
    setTab(selectedTab);
  };

  const contactSchema = yup.object().shape({
    email: yup.string().email(),
    template: yup.string().required(),
    subject: yup.string().required(),
  });

  const onSubmit = async values => {
    try {
      const body = {
        to: values.email,
        from: shop.email,
        fromname: `${me.displayName}`,
        subject: values.subject,
        message: values.message,
        sendToMe: values.sendToMe,
      };
      await functions.httpsCallable('sendEmailToCustomers')(body);
      handleClose();
    } catch (err) {
      console.log('Error: Contact Modal On Submit');
      console.log(err.message);
      console.log(err.response);
    }
  };

  return (
    <ModalWrapper size="md" show={show}>
      <IconButton className={classes.closeButton} onClick={handleClose}>
        <Close />
      </IconButton>
      <Formik
        initialValues={{
          email: email || '',
          template: 'Blank template',
          subject: '',
        }}
        validationSchema={contactSchema}
        onSubmit={onSubmit}
      >
        {(): ReactElement => (
          <Form>
            <Box className={classes.container}>
              <Typography variant="h2" className={classes.title}>
                Message customer
              </Typography>
              <TabContext value={tab}>
                <Tabs
                  variant="fullWidth"
                  indicatorColor="primary"
                  onChange={onTabChange}
                  value={tab}
                  aria-label="add-customer-mode"
                >
                  <Tab role="button" tabIndex={0} id="email-tab" aria-pressed={false} value="email" label="Email" />
                  {/* <Tab role="button" tabIndex={0} id="sms-tab" aria-pressed={false} value="sms" label="SMS" /> */}
                </Tabs>
                <TabPanel className={classes.tabPanel} value="email">
                  <Box className={`${classes.row} ${classes.inputRow}`}>
                    <Box className={classes.inputContainer}>
                      <Input name="email" placeholder="johndoe@email.com" label="Email address" />
                    </Box>
                    <Box className={classes.selectContainer}>
                      <Input fullWidth name="template" select label="Email Template">
                        {contactTemplates.map(template => (
                          <MenuItem value={template} key={template}>
                            {template}
                          </MenuItem>
                        ))}
                      </Input>
                    </Box>
                  </Box>
                  <Box className={classes.checkboxEmailContainer}>
                    <Input fullWidth name="subject" placeholder="Email subject" label="Subject" />
                  </Box>
                  <Input name="message" placeholder="Customer message here" multiline rows={12} label="Message" />
                  <Forms.Checkbox
                    name="sendToMe"
                    label={
                      <Typography className={classes.labelText}>
                        Send me a copy <span className={classes.secondaryLabel}>{shop.email}</span>
                      </Typography>
                    }
                  />
                </TabPanel>
              </TabContext>
              <Box className={classes.footer}>
                <Button variant="contained" type="submit">{`Send ${tab}`}</Button>
                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  );
};

export default ContactModal;
