import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, TableContainer, Table as BasicTable } from '@material-ui/core';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    backgroundColor: 'lightgray',
    color: 'white',
    maxWidth: 100 + '%',
  },
});

type Props = {
  children: React.ReactNode;
  classes: object;
};

const Table: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props);

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <BasicTable className={classes.table} size="small" aria-label="simple table">
        {props.children}
      </BasicTable>
    </TableContainer>
  );
};

export { Table, TableHeader, TableBody };
