import * as yup from 'yup';
import { idField } from '../base/model';
import { ShopRelatedDocument } from '../shop';

interface Alternate {
  downloadUrl: string;
  height: number;
  width: number;
}
export const alternateSchema = yup.object({
  downloadUrl: yup.string().required(),
  height: yup.number().required(),
  width: yup.number().required(),
});

export interface Metadata {
  id?: string;
  contentType?: string;
  originalFileName?: string;
  shopId?: string;
  productId?: string;
  productType?: string;
  assetType?: string;
}
export const metadataSchema = yup.object({
  id: idField,
  contentType: yup.string(),
  originalFileName: yup.string(),
  shopId: idField,
  productId: idField,
  productType: yup.string(),
  assetType: yup.string(),
});

export interface Options {
  folder?: string;
}
export const optionsSchema = yup.object({
  folder: yup.string(),
});

export interface Asset extends ShopRelatedDocument<Asset> {
  downloadUrl: string;
  metadata?: Metadata;
  options?: Options;
  smallVersion?: Alternate;
  mediumVersion?: Alternate;
}
export const assetSchema = yup.object({
  id: idField.required(),
  shopId: idField.required(),
  downloadUrl: yup.string().required(),
  metadata: metadataSchema,
  options: optionsSchema,
  smallVersion: alternateSchema,
  mediumVersion: alternateSchema,
});