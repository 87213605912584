import { createSlice } from '@reduxjs/toolkit';

export interface ExperimentState {
  active: Record<string, string>;
}

const initialState: ExperimentState = {
  active: {},
};


const experimentSlice = createSlice({
  name: 'experiments',
  initialState,
  reducers: {
    loadExperiments: (state) => {
      const storedExp = localStorage.getItem('experiments');
      if (storedExp) {
        state.active = JSON.parse(storedExp);
      }
    },
    addExperiment: (state, action) => {
      state.active[action.payload.name] = action.payload.variation;
      localStorage.setItem('experiments', JSON.stringify(state.active));
    }
  },

});

export const { addExperiment: addExperimentAction, loadExperiments: loadExperimentsAction } =  experimentSlice.actions;
export default experimentSlice.reducer;
