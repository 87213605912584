import React, {ReactNode, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Input, TextField, Theme} from "@material-ui/core";
import _ from 'lodash';
import {TextFieldProps} from "@material-ui/core/TextField/TextField";

type Props = TextFieldProps & {
  onEdit: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({

}));

const EditableInput: React.FC<Props> = (props: Props) => {
  const { onEdit } = props;
  const [value, setValue] = useState(props.value);
  const classes = useStyles();

  const onBlur = (event) => {
    const value = event.target.value;
    onEdit(value);
  }

  const onKeyUp = (event) => {
    const keyCode = event.keyCode || event.which

    if (keyCode === 13) {
      if (event.preventDefault) event.preventDefault();
      onBlur(event);
    }
  }

  const onChange = (event) => {
    setValue(event.target.value);
  }

  const muiProps: TextFieldProps = { ..._.omit(props, ['onEdit', 'children'], ), onBlur, onKeyUp, value, onChange, variant: "outlined"};

  return <TextField {...muiProps} disable-click="true"/>
}

export default EditableInput;
