import firebase from 'firebase/app';
import loginWithProvider from './loginWithProvider';
// Add the Firebase products that you want to use
import 'firebase/auth';

async function loginWithFacebook(config) {
  const provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope('public_profile');
  provider.addScope('email');
  if (config?.rerequest) {
    provider.setCustomParameters({
      auth_type: 'rerequest'
    });
  }
  const user = await loginWithProvider(provider, undefined, config?.onExistingAccount);
  return user;
}

export default loginWithFacebook;
