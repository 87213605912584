import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userModel from '../api/userApi';
import { User, UpdateUserModel } from '../../models/User';
import { signOut as signOutFirebase, getCurrentUser } from '@castiron/castiron-firebase';

export interface UserState {
  me: User;
  users: User[];
  user: User;
  loading: boolean;
  error: string;
}

const testUser = {
  id: '1',
  email: 'tester@redhookinteractive.com',
  firstName: 'Test',
  lastName: 'Tester',
  isActive: true,
  orgId: '1',
  role: 'admin',
};

const initialState: UserState = {
  me: process.env.REACT_APP_BYPASS_AUTH === 'true' ? testUser : null,
  users: [],
  user: null,
  loading: false,
  error: '',
};

//eslint-disable-next-line
const getUser = async (id: string): Promise<any> => {
  const response = await userModel.get(id);
  console.log('response from getuser: ', response);
  return response.data;
};

//eslint-disable-next-line
const listUsers = async (): Promise<any> => {
  const response = await userModel.list();
  console.log('response from listusers: ', response);
  return response.data;
};

const queryUsers = async (orgId: string) => {
  const response = await userModel.queryByOrgId(orgId);
  console.log(`response from queryUsers: `, response);
  return response.data;
};

//eslint-disable-next-line
const createUser = async (user: User): Promise<any> => {
  const response = await userModel.create(user);
  console.log('response from createUser: ', response);
  return response.data;
};

//eslint-disable-next-line
const removeUser = async (id: string): Promise<any> => {
  const response = await userModel.remove(id);
  console.log('response from removeUser: ', response);
  return response.data;
};

//eslint-disable-next-line
const updateUser = async (userObj: UpdateUserModel): Promise<any> => {
  const { id, ...user } = userObj;
  const response = await userModel.update(id, user);
  console.log('response from  updateUser: ', response);
  return response.data;
};

// Signs out of firebase and clears state.me
const signOut = () => {
  signOutFirebase();
  return null;
};

const getMyself = user => {
  //const currentUser = getCurrentUser();
  return user;
};

export const getUserAction = createAsyncThunk('users/getUser', getUser);
export const createUserAction = createAsyncThunk('users/createUser', createUser);
export const updateUserAction = createAsyncThunk('users/updateUser', updateUser);
export const removeUserAction = createAsyncThunk('users/removeUser', removeUser);
export const listUsersAction = createAsyncThunk('users/listUsers', listUsers);
export const queryUsersAction = createAsyncThunk('users/queryUsers', queryUsers);
export const getMyselfAction = createAsyncThunk('users/getMyself', getMyself);
export const signOutAction = createAsyncThunk('users/signOut', signOut);

const userSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // you can mutate state directly, since it is using immer behind the scenes\
    builder
      .addCase(getMyselfAction.pending, state => {
        state.loading = true;
      })
      .addCase(getMyselfAction.fulfilled, (state, action) => {
        state.me = action.payload;
        state.loading = false;
      })
      // .addCase(getMyselfAction.rejected, (state, action) => {
      //   state.error = action.payload;
      // })
      .addCase(getUserAction.pending, state => {
        state.loading = true;
      })
      .addCase(getUserAction.fulfilled, (state, action) => {
        state.me = action.payload;
        state.loading = false;
      })
      .addCase(listUsersAction.pending, state => {
        state.loading = true;
      })
      .addCase(listUsersAction.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(queryUsersAction.pending, state => {
        state.loading = true;
      })
      .addCase(queryUsersAction.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })
      .addCase(signOutAction.pending, state => {
        state.loading = true;
      })
      .addCase(signOutAction.fulfilled, state => {
        state.me = null;
      });
  },
});

export default userSlice.reducer;
