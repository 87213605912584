import React from 'react';
import { useAppDispatch } from '../../hooks';
import { Dialog } from '@material-ui/core';
import { closeModal } from '../../store/reducers/modalConductor';

type Props = {
  show: boolean;
  children: React.ReactNode;
  //eslint-disable-next-line
  size?: any;
  fullWidth?: boolean;
};

const ModalWrapper: React.FC<Props> = (props: Props) => {
  // eslint-disable-next-line
  const dispatch = useAppDispatch();
  const handleClose = (): void => {
    dispatch(closeModal());
  };

  return (
    <Dialog open={props.show} maxWidth={props.size || null} fullWidth={true} onClose={handleClose}>
      {props.children}
    </Dialog>
  );
};

export default ModalWrapper;
