import { set } from '..';

async function updateAsset(item) {
  try {
    // Filter out empty values
    Object.keys(item).map(key => {
      if (!item[key]) delete item[key];
    });
    // TODO: Refactor Table names to constants
    const result = await set('assets', item, item.id);
    console.log('result of updateAsset: ', result);
    return result;
  } catch (err) {
    console.log('Error Update Asset: ', err);
  }
}

export default updateAsset;
