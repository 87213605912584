import React, { useState, useEffect } from 'react';
import { DataGrid as Table, ColDef, RowModel } from '@material-ui/data-grid';

import { Grid } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { GridHeaders } from './GridHeaders';
import { useWindowSize } from '../../../utils';
type Props = {
  classes?: object;
  rows: RowModel[];
  columns: ColDef[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400,
    },
  }),
);

export const DataGrid: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const size = useWindowSize();
  const [editedColumns, setEditedColumns] = useState<ColDef[]>([]);
  useEffect(() => {
    const columnLength = props.columns.filter(col => !col.hide).length;
    setEditedColumns(
      props.columns.map(col => ({
        ...col,
        width:
          !col.width && ((size.width - (48 + columnLength * 2)) * 0.95) / columnLength >= 150
            ? ((size.width - (48 + columnLength * 2)) * 0.95) / columnLength
            : col.width || 150,
      })),
    );
  }, [props.columns, size]);
  return (
    <Grid container style={{ margin: '0 auto', width: '95%' }}>
      <Grid item xs={12}>
        <div style={{ height: 600, width: '100%' }}>
          <Table
            disableColumnFilter={false}
            components={{
              header: GridHeaders,
            }}
            checkboxSelection
            density="compact"
            rows={props.rows}
            columns={editedColumns}
            disableColumnMenu={false}
            icons={{ ColumnMenu: Menu }}
          />
        </div>
      </Grid>
    </Grid>
  );
};
