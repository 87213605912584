import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { customers } from '@castiron/castiron-firebase';
import {Customer} from "@castiron/domain";

export interface CustomersState {
  customers: Customer[];
  loading: boolean;
  error: string;
}

const initialState: CustomersState = {
  customers: [],
  loading: false,
  error: '',
};

const getCustomers = async (shopId: string) => {
  const response = await customers.queryByShopId(shopId);
  console.log('response from getCustomers: ', response);
  return response;
};

const getCustomerById = async (id: string) => {
  const response = await customers.get(id);
  console.log('response from getCustomerById: ', response);
  return response;
};

const createCustomer = async customer => {
  const response = await customers.create(customer);
  console.log('response from createCustomer: ', response);
  return response;
};

const updateCustomer = async customer => {
  const response = await customers.update(customer);
  console.log('response from updateCustomer: ', response);
  return response;
};

export const getCustomersAction = createAsyncThunk('customers/getCustomers', getCustomers);
export const getCustomerByIdAction = createAsyncThunk('customers/getCustomerById', getCustomerById);
export const createCustomerAction = createAsyncThunk('customers/createCustomer', createCustomer);
export const updateCustomerAction = createAsyncThunk('customers/updateCustomer', updateCustomer);

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCustomersAction.pending, state => {
        state.loading = true;
      })
      .addCase(getCustomersAction.fulfilled, (state, action) => {
        //@ts-ignore
        state.customers = action.payload;
        state.loading = false;
      });
  },
});

export default customersSlice.reducer;
