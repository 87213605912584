import firebase from 'firebase';
import _firestore from '@google-cloud/firestore';
import moment from 'moment';
import { ShopRelatedDocument, ShopRelatedRepository } from '../shop';
import { Customer } from '../customer';
import { Coupon } from '../coupon';
import { Condition, FieldFunctions } from '../base/repository';
import { Address } from '../address';
import { SelectedInputFieldValue } from '../inputfield/model';
import { Product } from '../product';
import { Tier } from '../account';
import { Attribution } from '../attribution';
import { LineItem, Order } from '../order';
import dataOnly from '../_lib/dataOnly';

export interface Fees {
  stripeFees?: number;
  castIronFees?: number;
  totalCustomerFees?: number;
  castironCustomerFees?: number;
  totalArtisanFees?: number;
  castironArtisanFees?: number;
  totalFees?: number;
  customerPaidStripeFees?: boolean;
  customerFeePercent?: number;
  artisanFeePercent?: number;
  applicationFees?: number;
  takeRate?: number;
}

export interface TransactionTotals extends Fees {
  subtotal: number;
  taxes: number;
  fulfillmentFee: number;
  coupon: number;
  totalWithTax: number;
  totalWithoutFees: number;
  total: number;
  subTotalsWithFulfillment?: number;
  subTotalWithCoupon?: number;
}

export interface ProcessorInfo {
  name: 'stripe';
  transactionIdentifier: string;
}

export interface ShippingInfo {
  recipientName: string;
  message?: string;
  address: Address;
}

export interface LegalInfo {
  hasAgreedToOrderDetails: boolean;
  hasAgreedToTerms: boolean;
}

export interface CartItem {
  quantity: number;
  shopId?: string;
  product: Product;
  selectedVariationValues?: SelectedInputFieldValue[];
}

export type CarrierOption = 'usps' | 'ups' | 'fedex' | 'dhlexpress' | 'other';

export interface FulfillOrderInfo {
  note: string;
  trackingNumber?: string;
  shippingCarrier?: CarrierOption;
  shippingCarrierLabel?: string;
  emailCopy?: boolean;
  trackingNumberUrl?: string;
}

export type FrontendTransactionState =
  | 'archived'
  | 'canceled'
  | 'completed'
  | 'draft'
  | 'fulfilled'
  | 'new'
  | 'open'
  | 'paid'
  | 'pending'
  | 'rejected'
  | 'sent'
  | 'unknown';
export type TransactionContext = 'order' | 'quote';

export interface Transaction extends ShopRelatedDocument<Transaction> {
  customer: string;
  customerObj?: Customer;
  order: Order;
  products?: CartItem[];
  totals?: TransactionTotals;
  transactionStatus: 'requested' | 'pending' | 'succeeded' | 'failed';
  notes?: string;
  status:
  | 'active'
  | 'inactive'
  | 'deleted'
  | 'open'
  | 'fulfilled'
  | 'completed'
  | 'canceled'
  | 'proposed'
  | 'rejected'
  | 'agreed';
  coupon?: Coupon;
  processor?: ProcessorInfo;
  isGift?: boolean;
  shippingInfo?: ShippingInfo;
  tier: Tier;
  artisanNotes?: string;
  legalInfo?: LegalInfo;
  fulfillOrderInfo?: FulfillOrderInfo;
  rejectionNote?: string;
  attribution?: Attribution;
  isArchived?: boolean;
  frontendState?: (context: TransactionContext) => FrontendTransactionState
}

export const backendStateToFrontendState = (tx: Transaction, context: TransactionContext): FrontendTransactionState => {
  const { status, transactionStatus: txStatus, isArchived } = tx;

  /* order page statuses */
  if (context === 'order') {
    if (status === 'open' && txStatus === 'succeeded') return 'open';
    if (status === 'completed' && txStatus === 'succeeded') return 'completed';
    if (status === 'fulfilled' && txStatus === 'succeeded') return 'fulfilled';
    if (status === 'rejected') return 'rejected';
    if (status === 'deleted' || status === 'canceled') return 'canceled';
  }

  /* these represent quote page statuses */
  if (context === 'quote') {
    if (isArchived) return 'archived';
    if (txStatus === 'succeeded') return 'paid';
    if (status === 'open') return 'new';
    if (status === 'proposed') return 'draft';
    if (status === 'agreed') return 'pending';
    if (status === 'rejected') return 'rejected';
    if (status === 'deleted' || status === 'canceled') return 'canceled';
  }

  return 'unknown';
};

export class TransactionRepository extends ShopRelatedRepository<Transaction> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'transactions', fieldFunctions);
  }

  private findStandardTransactions(): Condition<Transaction>[] {
    return [{ field: 'order.type', operator: '==', value: 'standard' }];
  }

  private findCustomTransactions(): Condition<Transaction>[] {
    return [{ field: 'order.type', operator: '==', value: 'custom' }];
  }

  public async getStandardTransactions(shopId: string): Promise<Transaction[]> {
    return this.find({
      where: [this.whereShopIs(shopId), ...this.findStandardTransactions()],
      orderBy: [{ field: 'createdAt', direction: 'desc' }],
    });
  }

  public async getCustomTransactions(shopId: string): Promise<Transaction[]> {
    return this.find({
      where: [this.whereShopIs(shopId), ...this.findCustomTransactions()],
      orderBy: [{ field: 'createdAt', direction: 'desc' }],
    });
  }

  public async getHistoricalTransactions({
    shopId,
    startDate,
    endDate,
  }: {
    shopId: string;
    startDate: number;
    endDate: number;
  }) {
    const results = await this.find({
      where: [
        this.whereShopIs(shopId),
        { field: 'transactionStatus', operator: '==', value: 'succeeded' },
        { field: 'createdAt', operator: '>=', value: startDate },
        { field: 'createdAt', operator: '<', value: endDate },
      ],
      orderBy: [{ field: 'createdAt', direction: 'desc' }],
    });
    /* need to do this because firestore isn't happy and about multiple property inequalities in a single query,
     * should be very rare that this causes any performance issues, as the time series should reduce the number of transactions significantly
     */
    return results.filter((tx: Transaction): boolean => tx.status !== 'deleted');
  }

  public findTransactionsForCustomerAndCoupon(
    shopId: string,
    customerId: string,
    couponId: string,
  ): Promise<Transaction[]> {
    if (shopId && customerId && couponId) {
      const comparisons: Condition<Transaction>[] = [
        {
          field: 'shopId',
          operator: '==',
          value: shopId,
        },
        {
          field: 'customer',
          operator: '==',
          value: customerId,
        },
        {
          field: 'coupon.id',
          operator: '==',
          value: couponId,
        },
      ];
      return this.find({ where: comparisons });
    }
    return Promise.resolve([]);
  }

  public async create(transaction: Transaction): Promise<Transaction> {
    const accountRef = this.db.collection('accounts').doc(transaction.shopId);
    const newTxRef = this.db.collection('transactions').doc();
    return this.db.runTransaction(async tx => {
      const account = await tx.get(accountRef);
      const orderNumberCounter = account.data().orderNumberCounter + 1;
      tx.update(accountRef, {
        orderNumberCounter,
      });
      const newTx = {
        ...transaction,
        createdAt: moment().unix(),
        order: {
          ...transaction.order,
          orderNumber: orderNumberCounter.toString(),
        },
      };
      tx.create(newTxRef, dataOnly(newTx));
      return {
        id: newTxRef.id,
        ...newTx,
      } as Transaction;
    });
  }

  public async findByPaymentDueDate(start: number, end: number): Promise<Transaction[]> {
    return this.find({
      where: [
        { field: 'order.paymentDueDate', operator: '>=', value: start },
        { field: 'order.paymentDueDate', operator: '<', value: end },
        { field: 'transactionStatus', operator: '==', value: 'requested' },
      ],
    });
  }

  protected enrichWith(): any {
    return {
      frontendState(context: TransactionContext) {
        return backendStateToFrontendState(this, context);
      },
    };
  }
}

export const cartItemToOrderedProduct = (cartProduct: CartItem): LineItem => ({
  id: cartProduct.product.id,
  title: cartProduct.product.title,
  type: cartProduct.product.type,
  price: (cartProduct.product as Product).price,
  description: cartProduct.product.description,
  category: cartProduct.product.category,
  quantity: cartProduct.quantity,
  total: cartProduct.product.price * cartProduct.quantity,
});

export const isCustomOrder = (items: LineItem[]): boolean => {
  const custOrder = items ? items.find(p => p?.type === 'custom') : null;
  return !!custOrder;
};
