import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { products } from '@castiron/castiron-firebase';
import {BaseProduct} from "@castiron/domain";

export interface ProductsState {
  products: BaseProduct[];
  loading: boolean;
  error: string;
}

const initialState: ProductsState = {
  products: [],
  loading: false,
  error: '',
};

const getProducts = async (shopId: string) => {
  const response = await products.queryByShopId(shopId);
  console.log('response from getProducts: ', response);
  return response;
};

const getProductById = async (id: string) => {
  const response = await products.get(id);
  console.log('response from getProductById: ', response);
  return response;
};

const createProduct = async product => {
  const response = await products.create(product);
  console.log('response from createProduct: ', response);
  return response;
};

const updateProduct = async product => {
  const response = await products.update(product);
  console.log('response from updateProduct: ', response);
  return response;
};

export const getProductsAction = createAsyncThunk('products/getProducts', getProducts);
export const getProductByIdAction = createAsyncThunk('products/getProductById', getProductById);
export const createProductAction = createAsyncThunk('products/createProduct', createProduct);
export const updateProductAction = createAsyncThunk('products/updateProduct', updateProduct);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProductsAction.pending, state => {
        state.loading = true;
      })
      .addCase(getProductsAction.fulfilled, (state, action) => {
        //@ts-ignore
        state.products = action.payload;
        state.loading = false;
      });
  },
});

export default productsSlice.reducer;
