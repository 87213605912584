import { set } from '..';

async function createTransaction(data) {
  console.log('createTransaction: ', data);
  const transaction = {
    ...data,
  };
  const result = await set('transactions', transaction);
  console.log('result of createTransaction: ', result);
  return result;
}

export default createTransaction;
