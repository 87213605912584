import React, { ReactNode, useEffect, useState } from 'react';
import { Checkbox, makeStyles, TextField, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ChevronDownIcon from '../assets/chevron-down.svg';
import SvgIcon from '../SvgIcon';

type Props = {
  options: any[];
  rootClass?: string;
  value?: string[];
  onChange?: (event, value) => void;
  fullWidth?: boolean;
  getOptionLabel?: (option: any) => string;
  renderOption?: (option: any) => ReactNode;
  placeholderPrefix?: string;
  allOption?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    '&::placeholder': {
      color: theme.branding.castiron,
      fontWeight: 600,
      opacity: 1,
      fontSize: 14,
    },
    cursor: 'pointer',
  },
  autocompleteRoot: {
    '& .MuiChip-root': {
      display: 'none',
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: 0,
      paddingLeft: 8,
      height: 40,
      minWidth: 200,
      borderRadius: 4,
    },
    '& .MuiFormControl-root': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  icon: {
    height: 40,
    width: 40,
    color: theme.branding.castiron,
  },
  endAdornment: {
    top: -1,
  },
}));

const MultipleSelect: React.FC<Props> = (props: Props) => {
  const {
    options,
    rootClass,
    value,
    onChange,
    fullWidth,
    getOptionLabel,
    renderOption,
    placeholderPrefix,
    allOption,
  } = props;
  const classes = useStyles();

  const [selectedValues, setSelectedValues] = useState<any[]>(value);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const placeholder = placeholderPrefix || 'Show';

  const onChangeHandler = (event, values) => {
    if (!!allOption && !allSelected && values.includes(allOption)) {
      setAllSelected(true);
      setSelectedValues([allOption]);
      onChange(event, []);
    } else if (!!allOption && allSelected && values.includes(allOption)) {
      setAllSelected(false);
      const noAllVals = values.filter(v => v !== allOption);
      setSelectedValues(noAllVals);
      onChange(event, noAllVals);
    } else {
      setSelectedValues(values);
      onChange(event, values);
    }
  };

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  return (
    <Autocomplete
      popupIcon={
        <SvgIcon className={classes.icon}>
          <ChevronDownIcon />
        </SvgIcon>
      }
      closeIcon={
        <SvgIcon className={classes.icon}>
          <ChevronDownIcon />
        </SvgIcon>
      }
      onChange={onChangeHandler}
      value={!selectedValues && value ? value : selectedValues}
      disableClearable
      classes={{ endAdornment: classes.endAdornment, root: `${classes.autocompleteRoot} ${rootClass}` }}
      multiple
      fullWidth={fullWidth}
      options={allOption ? [allOption, ...options] : options}
      disableCloseOnSelect
      getOptionLabel={(option: any): string => (getOptionLabel ? getOptionLabel(option) : option)}
      renderOption={(option: any, { selected }): ReactNode => (
        <>
          <Checkbox color="primary" checked={selected} />
          {renderOption ? renderOption(option) : option}
        </>
      )}
      renderInput={(params): ReactNode => {
        return (
          <TextField
            {...params}
            InputProps={{ ...params.InputProps, classes: { input: classes.input } }}
            variant="outlined"
            placeholder={`${placeholder}: ${selectedValues.join(', ')}`}
          />
        );
      }}
    />
  );
};

export default MultipleSelect;
