import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import {Theme} from "@material-ui/core";


type Props = {
  src: any;
  className?: string;
  height?: string;
  width?: string;
  alt: string;
  backgroundColor?: string;
  containerClassName?: string;
};

interface StyleProps {
  backgroundColor?: string;
  width: string;
  height: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  image: (props) => ({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    maxWidth: props.width || '115px',
    maxHeight: props.height || '115px',
  }),
  container: (props) => ({
    background: props.backgroundColor || theme.branding.gray[400],
    height: '100%',
  }),
  blank: (props: Record<string, any>) => ({
    width: props.width || '115px',
    height: props.height || '115px',
  }),
}));

const ProductImage: React.FC<Props> = (props: Props) => {
  const { src, className, alt, height, width, backgroundColor, containerClassName } = props;
  const classes = useStyles({height, width, backgroundColor});

  return (
    <div className={containerClassName || classes.container}>
      {src
        ? <img src={src} className={`${classes.image} ${className}`} alt={alt}/>
        : <div className={classes.blank}>&nbsp;</div>
      }
    </div>
  );
};

export default ProductImage;
