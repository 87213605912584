function getSafeUser(user) {
  if (!user) return null;
  console.log('user in getSafeUser: ', user);
  const safeUser = {
    email: user.email,
    uid: user.uid,
    displayName: user.displayName,
  };

  return safeUser;
}

export default getSafeUser;
