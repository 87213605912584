import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  newChip: {
    backgroundColor: theme.branding.blueberry,
    padding: '3px 8px',
    fontSize: '9px',
    lineHeight: '11px',
    color: 'RGB(255, 255, 255)',
    fontWeight: 700,
    marginLeft: 5,
    borderRadius: 9,
    width: 'fit-content',
  },
}));

interface Props extends React.HTMLAttributes<HTMLSpanElement> {}

const NewChip: React.FC<Props> = (props: Props) => {
  const { className, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <span className={`${classes.newChip} ${props.className}`} {...restOfProps}>
      New!
    </span>
  );
};

export default NewChip;
