import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { Typography, Grid } from '@material-ui/core';
import LoginForm from './LoginForm';
import '../Auth.scss';

type Props = {
  setUser: Function;
};

const Login: React.FC<Props> = (props: Props) => {
  const { myself } = useAppSelector(state => ({
    myself: state.users.me,
  }));

  if (myself) {
    return <Redirect to="/" />;
  }

  return (
    <Grid item xs>
      <LoginForm setUser={props.setUser} />
      <Grid container justify="center" className="auth-footer">
        <Grid container>
          <Grid item xs>
            <Typography align="center">
              Dont have an account? <Link to="/sign-up">Sign Up</Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
