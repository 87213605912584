import React, { ReactNode, useState } from 'react';
import SideDrawer from './SideDrawer/SideDrawer';
import Header from './Header/Header';
import Footer from './Footer';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  children: ReactNode;
  setupMode?: boolean;
  setSetupMode?: (value) => void;
};

const Layout: React.FC<Props> = (props: Props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    innerContainer: {
      flexGrow: 1,
    },
    layout: {
      display: 'flex',
      width: '100%',
    },
    mainContentArea: {
      [theme.breakpoints.up('md')]: {
        paddingTop: 24,
        paddingLeft: 56,
        paddingRight: 35,
        minHeight: 'calc(100vh - 200px)',
        maxWidth: '1300px',
        margin: '0 auto',
      },
      flexGrow: 1,
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
        minHeight: 'calc(100vh - 180px)',
      },
    },
  }))();

  return (
    <div className={classes.root}>
      <>
        <div className={classes.layout}>
          <SideDrawer setDrawerOpen={setDrawerOpen} open={drawerOpen} />
          <div className={classes.innerContainer}>
            <Header setDrawerOpen={setDrawerOpen} />
            <div className={classes.mainContentArea}>{props.children}</div>
            <Footer />
          </div>
        </div>
      </>
    </div>
  );
};

export default Layout;
