import React from 'react';
import { CheckboxWithLabel } from 'formik-material-ui';
import { Field, FormikErrors, FormikTouched } from 'formik';

// @ts-ignore
function Checkbox(props) {
  return (
    <Field
      color="primary"
      component={CheckboxWithLabel}
      type="checkbox"
      name={props.name}
      Label={{ label: props.label }}
    />
  );
}

export default Checkbox;
