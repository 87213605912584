import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Logo } from '@castiron/components';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';

type Props = {
  isAuth?: boolean;
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 32,
    paddingBottom: 32,
  },
  innerPadding: {
    paddingLeft: 300,
    paddingRight: 105,
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    alignItems: 'center',
  },
  link: {
    display: 'flex',
  },
}));

const FooterDesktop: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { me } = useAppSelector(state => ({
    me: state.users.me,
  }));

  return (
    <div className={`${classes.container} ${!props.isAuth && classes.innerPadding}`}>
      <Logo width="107px" />
      <div className={classes.linksContainer}>
        <Typography variant="caption" color="textSecondary">
          &copy; {new Date().getFullYear()} Castiron
        </Typography>
        <Link to={{ pathname: '/contact-us' }} className={classes.link}>
          {me && (
            <Typography variant="caption" color="textSecondary">
              Contact Us
            </Typography>
          )}
        </Link>
        <Link className={classes.link} target="_blank" to={{ pathname: 'https://castiron.me/privacy-policy' }}>
          <Typography variant="caption" color="textSecondary">
            Privacy Policy
          </Typography>
        </Link>
        <Link className={classes.link} target="_blank" to={{ pathname: 'https://castiron.me/terms-conditions' }}>
          <Typography variant="caption" color="textSecondary">
            Terms &amp; Conditions
          </Typography>
        </Link>
      </div>
    </div>
  );
};

export default FooterDesktop;
