import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { transactions } from '@castiron/castiron-firebase';
import {Transaction} from "@castiron/domain";

export interface TransactionsState {
  transactions: Transaction[];
  historicalTransactions: Transaction[];
  loading: boolean;
  error: string;
}

const initialState: TransactionsState = {
  transactions: [],
  historicalTransactions: [],
  loading: false,
  error: '',
};

const listTransactions = async (id: string) => {
  const response = await transactions.queryByShopId(id);
  console.log('response from listTransactions: ', response);
  return response;
};

const getHistoricalTransactions = async ({ id, days }) => {
  const response = await transactions.queryByShopId(id, days);
  console.log('response from getHistoricalTransactions: ', response);
  return response;
};

const getTransactionById = async (id: string) => {
  const response = await transactions.get(id);
  console.log('response from getTransactionById: ', response);
  return response;
};

const createTransaction = async transaction => {
  const response = await transactions.create(transaction);
  console.log('response from createTransaction: ', response);
  return response;
};

const updateTransaction = async transaction => {
  const response = await transactions.update(transaction);
  console.log('response from updateTransaction: ', response);
  return response;
};

export const listTransactionsAction = createAsyncThunk('transactions/listTransactions', listTransactions);
export const createTransactionAction = createAsyncThunk('transactions/createTransaction', createTransaction);
export const updateTransactionAction = createAsyncThunk('transactions/updateTransaction', updateTransaction);
export const getTransactionByIdAction = createAsyncThunk('transactions/getTransactionById', getTransactionById);
export const getHistoricalTransactionsAction = createAsyncThunk(
  'transactions/getHistoricalTransactions',
  getHistoricalTransactions,
);

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(listTransactionsAction.pending, state => {
        state.loading = true;
      })
      .addCase(listTransactionsAction.fulfilled, (state, action) => {
        //@ts-ignore
        state.transactions = action.payload;
        state.loading = false;
      })
      .addCase(getHistoricalTransactionsAction.pending, state => {
        state.loading = true;
      })
      .addCase(getHistoricalTransactionsAction.fulfilled, (state, action) => {
        //@ts-ignore
        state.historicalTransactions = action.payload;
        state.loading = false;
      });
  },
});

export default transactionsSlice.reducer;
