import { set } from '..';

async function updateTransaction(item) {
  try {
    // Filter out empty values
    // Object.keys(item).map(key => {
    //   if (!item[key]) delete item[key];
    // });
    console.log('updateTransaction item: ', item);
    // TODO: Refactor Table names to constants
    const result = await set('transactions', item, item.id);
    console.log('result of updateTransaction: ', result);
    return result;
  } catch (err) {
    console.log('Error Update Transaction: ', err);
  }
}

export default updateTransaction;
