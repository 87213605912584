import { set } from '..';

async function updateAccount(item) {
  // TODO: Refactor Table names to constants
  // Filter blank props
  Object.keys(item).map(key => {
    if (!item[key]) delete item[key];
  });
  const result = await set('accounts', item, item.id);
  console.log('result of updateAccount: ', result);
  return result;
}

export default updateAccount;
