import React from 'react';
import { Grid, GridProps, makeStyles, Theme } from '@material-ui/core';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme: Theme) => ({
  default: {},
}));

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type PageContainerProps = Override<
  GridProps,
  {
    helmetTitle: string;
  }
>;

// Main page container with Helmet
const PageContainer: React.FC<PageContainerProps> = (props: PageContainerProps) => {
  const { helmetTitle, className, children, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <Grid container className={`${classes.default} ${className}`} {...{ restOfProps }}>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>

      {children}
    </Grid>
  );
};

export default PageContainer;
