import axios from 'axios';
import axiosRetry from 'axios-retry';

// const setUpInterceptors = instance => {
//   instance.interceptors.response.use(null, error => {
//     if (error.config) {
//       return axios.request(error.config);
//     }
//   });

//   return instance;
// };

//eslint-disable-next-line
const setUpAxios = () => {
  const instance = axios.create();
  axiosRetry(instance, { retries: 3 });
  return instance;
};

export default setUpAxios();
