import { get } from '../index';

async function getAccount(id) {
  try {
    return await get('accounts', id);
  } catch (err) {
    console.log('Error Get Account: ', err);
  }
}

export default getAccount;
