import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { shops } from '@castiron/castiron-firebase';
import { Shop } from '@castiron/domain';
import firebase from 'firebase';
//@ts-ignore
const functions = firebase && firebase.functions ? firebase.functions() : null;

export interface ShopState {
  shops: Shop[];
  shop: Shop;
  blockedWebsiteUrls: string[];
  stripeLoginLink: any;
  stripeAccount: any;
  loading: boolean;
  error: string;
  formRef: any;
  redirectUrl: string;
  stripeRefresh: boolean;
}

const initialState: ShopState = {
  shops: [],
  shop: null,
  redirectUrl: '',
  stripeLoginLink: '',
  stripeAccount: {},
  stripeRefresh: false,
  blockedWebsiteUrls: [],
  formRef: null,
  loading: false,
  error: '',
};

//eslint-disable-next-line
const getShop = async (id: string) => {
  const response = await shops.get(id);
  console.log('response from getshop: ', response);
  return response;
  // return {
  //   id: '1',
  //   businessName: 'test',
  //   phone: 'test',
  //   address: 'test',
  //   addressTwo: 'test',
  //   category: 'test',
  //   websiteURL: 'test',
  //   passwordProtection: 'test',
  //   password: 'test',
  //   message: 'test',
  // };
};

//eslint-disable-next-line
const getShopsList = async () => {
  const response = await shops.getShopsList();
  console.log('response from getShopList: ', response);
  return response;
  // return {
  //   businessName: "Shop Name"
  //   email: "email@email.com"
  //   id: "0000000000000000000000000000000"
  //   websiteUrl: "url"
  // };
};

//eslint-disable-next-line
const createShop = async (shop: any = {}) => {
  //@ts-ignore
  const response = await shops.create(shop);
  console.log('response from createShop: ', response);
  //@ts-ignore
  return response.data;
};

//eslint-disable-next-line
// const removeShop = async (id: string) => {
//   const response = await shopModel.remove(id);
//   console.log('response from removeShop: ', response);
//   return response.data;
// };

// //eslint-disable-next-line
const updateShop = async (shop: any) => {
  const response = await shops.update(shop);
  console.log('response from  updateShop: ', response);
  return response;
};

const getWebsiteUrls = async () => {
  const response = await functions.httpsCallable('checkShopUrls')();
  return response.data;
};

const getStripeAccount = async () => {
  const response = await functions.httpsCallable('getStripeAccount')();
  console.log('getStripeAccount response: ', response);
  return response.data;
};

const getStripeAccountLoginLink = async () => {
  const response = await functions.httpsCallable('getStripeAccountLoginLink')();
  console.log('getStripeAccountLoginLink Response: ', response);
  return response.data;
};

export const getShopAction = createAsyncThunk('shops/getShop', getShop);
export const getShopsListAction = createAsyncThunk('shops/getShopsList', getShopsList);
export const createShopAction = createAsyncThunk('shops/createShop', createShop);
export const updateShopAction = createAsyncThunk('shops/updateShop', updateShop);
export const getWebsiteUrlsAction = createAsyncThunk('shops/getWebsiteUrls', getWebsiteUrls);
export const getStripeAccountAction = createAsyncThunk('shops/getStripeAccount', getStripeAccount);
export const getStripeAccountLoginLinkAction = createAsyncThunk(
  'shops/getStripeAccountLoginLink',
  getStripeAccountLoginLink,
);
// export const removeShopAction = createAsyncThunk('shops/removeShop', removeShop);

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setRedirectUrl(state, action) {
      state.redirectUrl = action.payload;
    },
    setStripeRefresh(state, action) {
      state.stripeRefresh = action.payload;
    },
  },
  extraReducers: builder => {
    // you can mutate state directly, since it is using immer behind the scenes\
    builder
      .addCase(getShopAction.pending, state => {
        state.loading = true;
      })
      .addCase(getShopAction.fulfilled, (state, action) => {
        //@ts-ignore
        state.shop = action.payload;
        state.loading = false;
      })
      .addCase(getShopsListAction.pending, state => {
        state.loading = true;
      })
      .addCase(getShopsListAction.fulfilled, (state, action) => {
        //@ts-ignore
        state.shops = action.payload;
        state.loading = false;
      })
      .addCase(createShopAction.pending, state => {
        state.loading = true;
      })
      .addCase(createShopAction.fulfilled, (state, action) => {
        state.shop = action.payload;
        state.loading = false;
      })
      .addCase(updateShopAction.pending, state => {
        state.loading = true;
      })
      .addCase(updateShopAction.fulfilled, (state, action) => {
        //@ts-ignore
        state.shop = action.payload;
        state.loading = false;
      })
      .addCase(getWebsiteUrlsAction.pending, state => {
        state.loading = true;
      })
      .addCase(getWebsiteUrlsAction.fulfilled, (state, action) => {
        // @ts-ignore
        state.blockedWebsiteUrls = action.payload;
        state.loading = false;
      })
      .addCase(getStripeAccountAction.pending, state => {
        state.loading = true;
      })
      .addCase(getStripeAccountAction.fulfilled, (state, action) => {
        // @ts-ignore
        state.stripeAccount = action.payload;
        state.loading = false;
      })
      .addCase(getStripeAccountLoginLinkAction.pending, state => {
        state.loading = true;
      })
      .addCase(getStripeAccountLoginLinkAction.fulfilled, (state, action) => {
        // @ts-ignore
        state.stripeLoginLink = action.payload;
        state.loading = false;
      });
  },
});

export const { setRedirectUrl, setStripeRefresh } = shopSlice.actions;

export default shopSlice.reducer;
