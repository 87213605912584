import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CastironSpinner from '../../assets/img/loading-spinner.gif';
import { Text } from '@castiron/components';

type Props = {
  show: boolean;
  size?: 'fullscreen' | 'content' | 'relative';
  label?: string;
};

const useStyles = makeStyles(() => ({
  image: {
    height: 90,
    width: 90,
  },
  containerAbsolute: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    zIndex: 10000,
  },
  containerRelative: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
  },
  containerContent: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    zIndex: 10000,
    width: '100vw',
  },
}));

const Spinner: React.FC<Props> = (props: Props) => {
  // const { spacing } = props;
  // const outputValue = spacing ? '0px' : spacing + 'px';
  const classes = useStyles();
  // const size = props.size || 'lg';

  const getClass = () => {
    switch (props.size) {
      case 'fullscreen':
        return classes.containerAbsolute;
      case 'relative':
        return classes.containerRelative;
      case 'content':
        return classes.containerContent;
      default:
        return classes.containerContent;
    }
  };

  return (
    <>
      {props.show && (
        <div className={getClass()}>
          {props.label && (
            <>
              <Text style={{marginBottom: 16}}>{props.label}</Text>
            </>
          )}
          <img src={CastironSpinner} className={classes.image}/>
        </div>
      )}
    </>
  );
};

export default Spinner;
