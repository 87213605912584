import { Attribution, TrafficSource, getSource } from "@castiron/domain";

export const sourceKey = 'castiron-attribition-source';
export const sourceUrlKey = 'castiron-attribition-sourceUrl';
const campaignKey = 'castiron-attribition-campaign';

export const determineSource = (): TrafficSource => {
  // @ts-ignore
  if (!process.browser) return undefined;

  const { referrer } = document;
  // @ts-ignore
  const existingSource: TrafficSource = sessionStorage.getItem(sourceKey);

  if (existingSource) return existingSource;

  let source = 'direct';
  if (referrer) {
    source = getSource(referrer);
  }

  sessionStorage.setItem(sourceKey, source);

  // @ts-ignore
  return source;
};

export const determineSourceUrl = (): string => {
  // @ts-ignore
  if (!process.browser) return undefined;

  const sourceUrl = sessionStorage.getItem(sourceUrlKey);
  if (sourceUrl) return sourceUrl === 'none' ? null : sourceUrl;

  const referrer = document.referrer ? document.referrer : null;
  sessionStorage.setItem(sourceUrlKey, referrer || 'none');

  return referrer;
};

export const determineCampaign = (): string => {
  // @ts-ignore
  if (!process.browser) return undefined;

  const urlParams = new URLSearchParams(window.location.search);
  const utmCampaign = urlParams.get('utm_campaign');
  const cid = urlParams.get('cid');

  let campaignId = utmCampaign || cid || null;

  if (campaignId) {
    sessionStorage.setItem(campaignKey, campaignId);
  } else {
    campaignId = sessionStorage.getItem(campaignKey);
  }

  return campaignId;
};

export const determineAttribution = (): Attribution => ({
  source: determineSource(),
  campaignId: determineCampaign(),
  referringSourceUrl: determineSourceUrl(),
});
