import React from 'react';
import { useTracking } from '@castiron/utils';

type Props = {
  // eslint-disable-next-line
  msg: any;
};

const SubmissionError: React.FC<Props> = (props: Props) => {
  const { trackEvent } = useTracking();

  trackEvent('Submission Error', {
    errorMessage: props.msg,
  });

  return (
    <>
      <div className="text-danger submission-error">{props.msg}</div>
      <p>
        Having issues? Contact <a href="mailto:help@castiron.me">help@castiron.me</a> to get help from our support team.
      </p>
    </>
  );
};

export default SubmissionError;
