import React from 'react';
import { Button as MUIButton, Theme, ButtonProps } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

type Props = Override<
  ButtonProps,
  {
    variant: 'text' | 'outlined' | 'contained';
  }
>;

const useStyles = makeStyles((theme: Theme) => ({
  button: {},
  outlined: {
    color: theme.palette.error.main,
    border: `1px solid ${fade(theme.palette.error.main, 0.5)}`,
    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: fade(theme.palette.error.light, 0.1),
    },
    '&$disabled': {
      border: `1px solid ${theme.palette.action.disabled}`,
    },
  },
  contained: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      '@media (hover: none)': {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  text: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, theme.palette.action.hoverOpacity),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const RedButton: React.FC<Props> = (props: Props) => {
  const { variant = 'contained', ...restOfProps } = props;
  const classes = useStyles();

  return (
    <MUIButton
      className={`
        ${classes.button} ${props.className} 
        ${variant === 'contained' && classes.contained} 
        ${variant === 'outlined' && classes.outlined} 
        ${variant === 'text' && classes.text}
      `}
      {...restOfProps}
    >
      {props.children}
    </MUIButton>
  );
};

export default RedButton;
