import create from './createAccount';
import get from './getAccount';
import update from './updateAccount';
import queryAccountByWebsiteUrl from './queryAccountByWebsiteUrl';

const api = {
  create,
  get,
  update,
  queryAccountByWebsiteUrl,
};

export default api;
