import React, { useState } from 'react';
import {
  Link,
  makeStyles,
  Table as MUITable,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
} from '@material-ui/core';
import { Shop } from '@castiron/domain';

interface ShopColumn {
  businessName: string;
  email: string;
  websiteUrl: string;
}

type Props = {
  shopRows: Shop[];
};

interface HeadCell {
  id: keyof ShopColumn;
  label: string;
}

// const tableHeaders = [
//   { header: 'Name' },
//   { header: 'Email' },
//   { header: 'URL' }
// ]

const headCells: HeadCell[] = [
  { id: 'businessName', label: 'Shop name' },
  { id: 'email', label: 'Shop owner email' },
  { id: 'websiteUrl', label: 'Link to shop' },
];

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    height: 67,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.common.white,
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.branding.ghostWhite,
    },
    '& a': {
      color: '#2963ca',
    },
  },
  name: {
    fontSize: 16,
    fontWeight: 700,
  },
  cellText: {
    fontSize: 16,
    color: '#474747',
  },
  headCell: {
    width: '33%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ShopColumn) => void;
  order: any;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof ShopColumn) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            className={classes.headCell}
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const ShopTable: React.FC<Props> = (props: Props) => {
  const { shopRows } = props;
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState<keyof ShopColumn>('businessName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ShopColumn) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <MUITable>
      {/* <TableHead>
        {shopColumns.map(column => (
          <TableCell key={column.header}>
            <TableSortLabel>
              {column.header}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableHead> */}
      <EnhancedTableHead classes={classes} onRequestSort={handleRequestSort} order={order} orderBy={orderBy} />
      <TableBody>
        {stableSort(shopRows, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => (
            <TableRow className={classes.row} hover key={row.id} tabIndex={-1}>
              <TableCell>
                <Typography className={classes.name}>{row.businessName}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.cellText}>{row.email}</Typography>
              </TableCell>
              <TableCell>
                <Link
                  className={classes.cellText}
                  href={`${process.env.REACT_APP_SHOP_URL}/${row.websiteUrl}`}
                  target="_blank"
                  rel="noopener"
                  color="inherit"
                  onClick={e => {
                    e.preventDefault;
                  }}
                >
                  {row.websiteUrl}
                </Link>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
      <TableFooter>
        <TablePagination
          count={shopRows.length}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
        ></TablePagination>
      </TableFooter>
    </MUITable>
  );
};

export default ShopTable;
