import axios from '../../lib/http-service';

// TODO: Placeholder
const getToken = () => {
  return '';
};

// interface Model {
//   get?: Function;
// }

// function apiFactory(name, opts = {}): Model {
//   const apiPath = name;
//   const apiUrl = `${basePath}/${apiPath}`;

//   return {
//     get: async function(id: string): Promise<any> {
//       return await axios({
//         url: `${apiUrl}/${id}`,
//         method: 'GET',
//       });
//     },
//   };
// }

class Model {
  apiPath?: string;
  apiUrl?: string;
  basePath?: string;

  constructor(options) {
    this.basePath = process.env.REACT_APP_API_URL;
    console.log('constructor options: ', options);
    this.apiPath = options.name;
    this.apiUrl = `${this.basePath}/${this.apiPath}`;
  }
  // eslint-disable-next-line
  get = async (id): Promise<any> => {
    const token = await getToken();
    //console.log('token', token);

    console.log('get this.apiUrl : ', this.apiUrl);
    const response = await axios({
      url: `${this.apiUrl}/${id}`,
      method: 'GET',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });

    console.log('getResponse', response);
    return response;
  };
  // eslint-disable-next-line
  list = async (): Promise<any> => {
    const token = await getToken();
    console.log('token', token);
    return await axios({
      url: `${this.apiUrl}`,
      method: 'GET',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };

  queryByOrgId = async (orgId: string): Promise<any> => {
    const token = await getToken();
    console.log('token', token);
    return await axios({
      url: `${this.apiUrl}?orgId=${orgId}`,
      method: 'GET',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };
  // eslint-disable-next-line
  remove = async (id): Promise<any> => {
    const token = await getToken();
    console.log('token', token);
    return await axios({
      url: `${this.apiUrl}/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };
  // eslint-disable-next-line
  update = async (id, data): Promise<any> => {
    const token = await getToken();
    console.log('token', token);
    return await axios({
      url: `${this.apiUrl}/${id}`,
      method: 'PUT',
      data,
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };
  // eslint-disable-next-line
  create = async (data): Promise<any> => {
    const token = await getToken();
    console.log('token', token);
    return await axios({
      url: `${this.apiUrl}`,
      method: 'POST',
      data,
      headers: {
        Authorization: `bearer ${token}`,
      },
    });
  };
}

export default Model;
