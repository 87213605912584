import React from 'react';
import { Grid, GridProps, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  default: {},
}));

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type PageContentProps = Override<GridProps, {}>;

// Main page content wrapper
const PageContent: React.FC<PageContentProps> = (props: PageContentProps) => {
  const { className, children, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <Grid container className={`${classes.default} ${className}`} {...restOfProps}>
      {children}
    </Grid>
  );
};

export default PageContent;
