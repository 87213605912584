import firebase from 'firebase';
import moment from 'moment';
import _firestore, { CollectionReference, DocumentData } from '@google-cloud/firestore';
import dataOnly from '../_lib/dataOnly';

export interface BaseDocument<T> {
  id?: string;
  createdAt?: number;
  updatedAt?: number;
  status?: 'active' | 'inactive' | 'deleted' | 'prelaunch' | string;
  data?: () => T;
}

export interface Condition<T> {
  field: keyof T | any;
  operator: firebase.firestore.WhereFilterOp;
  value: any;
}

export interface Order<T> {
  field: keyof T | any;
  direction: firebase.firestore.OrderByDirection;
}

export interface Query<T> {
  where?: Condition<T>[];
  orderBy?: Order<T>[];
  limit?: number;
  startAfter?: T;
}

export type PartialDoc<T> = Partial<T> | Record<string, any>;

export interface FieldFunctions {
  increment: (x: number) => any;
  delete: () => any;
  documentIdPath: () => any;
  arrayUnion: (elements: any[]) => any;
  arrayRemove: (elements: any[]) => any;
}

const WEB_FIELD_FUNCTIONS: FieldFunctions = {
  increment: firebase.firestore.FieldValue.increment,
  delete: firebase.firestore.FieldValue.delete,
  arrayRemove: firebase.firestore.FieldValue.arrayRemove,
  arrayUnion: firebase.firestore.FieldValue.arrayUnion,
  documentIdPath: firebase.firestore.FieldPath.documentId,
};

export const handleData = value => (value.data && value.data instanceof Function ? value.data() : value);

export class BaseRepository<T extends BaseDocument<T>> {
  protected readonly db: firebase.firestore.Firestore | _firestore.Firestore;

  private readonly collectionName: string;

  protected readonly fieldFunctions: FieldFunctions;

  constructor(
    firestore: firebase.firestore.Firestore | _firestore.Firestore,
    collectionName: string,
    fieldFunctions?: FieldFunctions,
  ) {
    this.db = firestore;
    this.collectionName = collectionName;
    this.fieldFunctions = fieldFunctions || WEB_FIELD_FUNCTIONS;
  }

  public async get(id: string): Promise<T | null> {
    const doc = await this.collection()
      .doc(id)
      .get();

    if (!doc.exists) return null;

    const data = doc.data();
    if (!data.status) {
      data.status = 'active';
    }
    return this.enrich({
      ...data,
      id: doc.id,
    } as T);
  }

  public async getAll(ids: string[]): Promise<T[]> {
    return this.collection()
      .where(this.fieldFunctions.documentIdPath(), 'in', ids)
      .get()
      .then(res =>
        res.docs.map(d =>
          this.enrich({
            ...d.data(),
            id: d.id,
          } as T),
        ),
      );
  }

  public async batch(items: T[]) {
    const batch = this.db.batch();
    items.forEach(i => {
      if (i.id) {
        const ref = this.collection().doc(i.id);
        // @ts-ignore
        batch.update(ref, i);
      } else {
        const ref = this.collection().doc();
        // @ts-ignore
        batch.set(ref, i);
      }
    });

    return batch
      .commit()
      .then(() => console.debug('Successfully batch uploaded'))
      .catch(err => {
        console.error('Error doing batch update', err);
        throw err;
      });
  }

  public async batchUpdate(items: PartialDoc<T>[]) {
    const batch = this.db.batch();
    items.forEach(i => {
      const ref = this.collection().doc(i.id);
      // @ts-ignore
      batch.update(ref, i);
    });

    return batch
      .commit()
      .then(() => console.debug('Successfully batch uploaded'))
      .catch(err => {
        console.error('Error doing batch update', err);
        throw err;
      });
  }

  public async getIn(ids: string[]): Promise<T[]> {
    return this.find({
      where: [{ field: 'id', operator: 'in', value: ids }],
    });
  }

  public async create(doc: T): Promise<T> {
    if (!doc.status) {
      doc.status = 'active';
    }
    let newDoc;
    const createdAt = moment().unix();
    if (doc.id) {
      newDoc = {
        ...this.prep(doc),
        createdAt,
      };
      await this.collection()
        .doc(doc.id)
        .set(newDoc);
    } else {
      newDoc = await this.collection().add({
        ...this.prep(doc),
        createdAt,
      });
    }
    return this.enrich({
      ...doc,
      id: newDoc.id,
    } as T);
  }

  public async upsert(doc: T): Promise<void> {
    return this.collection()
      .doc(doc.id)
      .set(
        {
          ...this.prep(doc),
          updatedAt: moment().unix(),
        },
        { merge: true },
      )
      .then(wr => {});
  }

  public async find(query: Query<T>): Promise<T[]> {
    let fsQuery: any = this.collection();

    if (query.where?.length > 0) {
      query.where.forEach(cond => {
        fsQuery = fsQuery.where(cond.field, cond.operator, cond.value);
      });
    }

    if (query.orderBy && query.orderBy.length > 0) {
      const firstOrder = query.orderBy.shift();
      if (firstOrder) {
        fsQuery = fsQuery.orderBy(firstOrder.field, firstOrder.direction);
        query.orderBy.forEach(o => {
          fsQuery = fsQuery.orderBy(o.field, o.direction);
        });
      }
    }

    if (query.limit) {
      fsQuery = fsQuery.limit(query.limit);
    }

    if (query.startAfter) {
      fsQuery = fsQuery.startAfter(this.prep(query.startAfter));
    }

    const result = await fsQuery.get();

    return result.docs.map(doc => {
      const data = doc.data();
      if (!data.status) {
        data.status = 'active';
      }
      return this.enrich({ ...data, id: doc.id });
    }) as T[];
  }

  public async findFieldStartsWith(field: string, startsWith: string, addlWheres: Condition<T>[] = []): Promise<T[]> {
    const strLength = startsWith.length;
    const strFrontCode = startsWith.slice(0, strLength - 1);
    const strEndCode = startsWith.slice(strLength - 1, startsWith.length);

    const startCode = startsWith;
    const endCode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

    return this.find({
      where: [...addlWheres, { field, operator: '>=', value: startCode }, { field, operator: '<', value: endCode }],
    });
  }

  public async update(doc: T): Promise<T> {
    console.debug(`Update for doc [${doc.id}]`, doc);
    if (!doc.status) {
      doc.status = 'active';
    }
    const updated = {
      ...this.prep(doc),
      updatedAt: moment().unix(),
    };
    await this.collection()
      .doc(doc.id)
      .update(updated);

    return this.enrich(updated);
  }

  public async updateProps(id: string, props: PartialDoc<T>): Promise<void> {
    console.debug(`Update for doc [${id}]`, props);

    const cleanProps = Object.fromEntries(
      Object.entries(props).map(([key, value]) =>
        [key, handleData(value)],
      ),
    );

    await this.collection()
      .doc(id)
      .update({
        ...cleanProps,
        updatedAt: moment().unix(),
      });
  }

  public async inactivate(id: string): Promise<void> {
    await this.collection()
      .doc(id)
      .update({
        status: 'inactive',
      });
  }

  public async activate(id: string): Promise<void> {
    await this.collection()
      .doc(id)
      .update({
        status: 'active',
      });
  }

  public async delete(id: string): Promise<void> {
    await this.collection()
      .doc(id)
      .update({
        status: 'deleted',
      });
  }

  public async listActive(): Promise<T[]> {
    return this.find({
      where: [{ field: 'status', operator: '==', value: 'active' }],
      orderBy: [{ field: 'createdAt', direction: 'desc' }],
    });
  }

  public async findWhereStatusIn(statuses: string[]): Promise<T[]> {
    return this.find({
      where: [{ field: 'status', operator: 'in', value: statuses }],
      orderBy: [{ field: 'createdAt', direction: 'desc' }],
    });
  }

  public async deleteFields(id: string, fieldNames: string[]): Promise<void> {
    await this.collection()
      .doc(id)
      .update(Object.fromEntries(fieldNames.map(f => [f, firebase.firestore.FieldValue.delete()])));
  }

  protected collection():
    | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
    | CollectionReference<DocumentData> {
    return this.db.collection(this.collectionName);
  }

  protected firstOrNull(results: T[]): T | null {
    return results.length > 0 ? results[0] : null;
  }

  protected enrichWith(): any {
    return undefined;
  }

  private enrich(doc: T): T {
    const enrich = this.enrichWith();
    const base = Object.assign(doc, {
      data() {
        return dataOnly(this);
      },
    });
    return enrich ? Object.assign(base, enrich) : base;
  }

  private prep(doc: T): T {
    return doc.data ? doc.data() : dataOnly(doc);
  }
}
