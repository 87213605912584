import React, { ReactNode } from 'react';
import { Box, Popover as MUIPopover, PopoverOrigin } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  children: ReactNode;
  anchorEl: Element;
  onClose: () => void;
  transformOrigin?: PopoverOrigin;
  paperClass?: string;
};

const useStyles = makeStyles(() => ({
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 8px',
  },
}));

const Popover: React.FC<Props> = (props: Props) => {
  const { children, anchorEl, onClose, transformOrigin, paperClass } = props;
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <MUIPopover
      classes={{
        paper: paperClass,
      }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={
        transformOrigin || {
          vertical: 'top',
          horizontal: 'right',
        }
      }
    >
      <Box className={classes.popoverContainer}>{children}</Box>
    </MUIPopover>
  );
};

export default Popover;
