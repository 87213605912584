import React from 'react';
import { ListItem, ListItemText, ListItemIcon, Typography, Badge, Link } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  label: string;
  path: string;
  external?: boolean;
  Icon?: any;
  itemCount?: number;
  onClick?: any;
};

const NavItem: React.FC<Props> = (props: Props) => {
  const { Icon } = props;

  const location = useLocation();
  let isActive;
  if (props.path === '/') isActive = props.path === location.pathname;
  else if (props.path === '/products' && props.label === 'Overview') isActive = location.pathname === '/products';
  else isActive = location.pathname.includes(props.label.toLowerCase()) || location.pathname.includes(props.path);

  const classes = makeStyles(theme => ({
    active: {
      backgroundColor: theme.branding.ghostWhite,
      color: '#2963ca',
      '.MuiCollapse-container &': {
        background: 'none',
      },
    },
    badge: {
      '& .MuiBadge-badge': {
        backgroundColor: theme.branding.callout,
        color: theme.palette.common.white,
        fontSize: 9,
        height: 16,
        lineHeight: 2,
      },
    },
    icon: {
      width: 16,
      height: 16,
    },
    text: {
      fontSize: 15,
    },
    title: {
      fontSize: 10,
      color: 'gray',
      marginLeft: 20,
      marginTop: 15,
    },
    listItem: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      borderRadius: '8px',
      marginBottom: 2,
      '&.MuiLink-root': {
        color: 'inherit',
      },
      '&:last-child': {
        marginBottom: 0,
      },
      '& .MuiListItemText-root': {
        margin: 0,
      },
      '.MuiCollapse-container &': {
        fontSize: 13,
        width: 'auto',
        marginLeft: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '&:hover': {
          background: 'none',
        },
      },
    },
    listItemText: {
      fontSize: 15,
      fontWeight: 'bold',
      lineHeight: 1.2,
    },
    smallerFont: {
      fontSize: 14,
    },
  }))();

  const isSmallerFont = props.label === 'Community' || props.label === 'Help' || props.label === 'FAQs';

  const NavComponent = !props.external ? NavLink : Link;

  return (
    <ListItem
      button
      component={NavComponent}
      data-href={props.path}
      to={!props.external ? props.path : null}
      href={props.external ? props.path : null}
      target={props.external ? '_blank' : '_self'}
      className={`${classes.listItem}${isActive ? ` ${classes.active}` : ''}`}
      onClick={props.onClick}
    >
      {props.Icon && (
        <ListItemIcon>
          <Icon className={classes.icon} />
        </ListItemIcon>
      )}
      <ListItemText
        disableTypography
        primary={
          <Typography variant="subtitle2" className={`${classes.listItemText} ${isSmallerFont && classes.smallerFont}`}>
            {props.label}
          </Typography>
        }
      />
      {props.itemCount && <Badge badgeContent={props.itemCount} className={classes.badge} />}
    </ListItem>
  );
};

export default NavItem;
