import { Hidden } from '@material-ui/core';
import React, { useEffect } from 'react';
import FooterDesktop from './FooterDesktop';
import FooterMobile from './FooterMobile';

type Props = {
  isAuth?: boolean;
};

declare global {
  interface Window {
    //Beacon: any;
    Beacon(method: string, id?: string): void;
  }
}

let authState = 'initialState';
const beaconKey = '4a44881e-cb97-483c-ab41-a668a5cc3099';

const Footer: React.FC<Props> = (props: Props) => {
  const initBeacon = (isAuth): void => {
    if (authState !== isAuth) {
      authState = isAuth;
      if (!isAuth) {
        window.Beacon('init', beaconKey);
      } else {
        window.Beacon('destroy');
      }
    }
  };

  useEffect(() => {
    initBeacon(props.isAuth);
  }, [props.isAuth]);

  return (
    <footer>
      <Hidden mdUp>
        <FooterMobile />
      </Hidden>
      <Hidden smDown>
        <FooterDesktop isAuth={props.isAuth} />
      </Hidden>
    </footer>
  );
};

export default Footer;
