import { set } from '..';
import getCurrentUser from '../../auth/getCurrentUser';

async function createShop() {
  try {
    const user = getCurrentUser();
    console.log('createUserShop: ', user);
    // TODO: Refactor Table names to constants
    const shop = {
      id: user.uid,
      // TODO: CreatedDate
    };
    const result = await set('shops', shop, user.uid);
    console.log('result of createShop: ', result);
    return result;
  } catch (err) {
    console.log('Error creating Shop: ', err);
  }
}

export default createShop;
