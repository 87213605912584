import create from './createShop';
import get from './getShop';
import getShopsList from './getShopsList';
import update from './updateShop';
import queryShopByWebsiteUrl from './queryShopByWebsiteUrl';

const api = {
  create,
  get,
  getShopsList,
  update,
  queryShopByWebsiteUrl,
};

export default api;
