import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Text } from '../';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    backgroundColor: 'RGB(240, 240, 240)',
    borderRadius: 4,
    padding: '0px 4px',
  },
  hashtag: {
    color: theme.branding.darkGray,
    marginRight: 5,
  },
}));

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
}

// Hashtag chip
const HashTagChip: React.FC<Props> = (props: Props) => {
  const { className, label, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <div className={`${classes.container} ${className}`} {...restOfProps}>
      <span className={classes.hashtag}>#</span> <Text presetFont="txt--s">{label}</Text>
    </div>
  );
};

export default HashTagChip;
