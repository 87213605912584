import React from 'react';
import { Link, Theme, LinkProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  default: {
    cursor: 'pointer',
  },
  blue: {
    color: theme.branding.blueberry,
  },
  inherit: {
    color: 'inherit',
  },
}));

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

type Props = Override<
  LinkProps,
  {
    underline?: boolean;
    fontSize?: number;
    notBlue?: boolean;
  }
>;

const CastironLink: React.FC<Props> = (props: Props) => {
  const { underline = false, fontSize, notBlue = false, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <Link
      className={`${classes.default} ${notBlue ? classes.inherit : classes.blue} ${props.className}`}
      style={{ textDecoration: underline ? 'underline' : '', fontSize: fontSize ? fontSize : 'inherit' }}
      {...restOfProps}
    >
      {props.children}
    </Link>
  );
};

export default CastironLink;
