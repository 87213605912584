import React, { useState } from 'react';
import { Avatar, Box, Grid, IconButton, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { openModal } from '../../store/reducers/modalConductor';
import { Check } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
  },
  secondaryTitle: {
    marginTop: 42,
    marginBottom: 8,
    fontWeight: 700,
    fontSize: 16,
  },
  box: {
    border: `1px solid ${theme.branding.gray[600]}`,
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 32,
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 32,
    alignItems: 'center',
  },
  label: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '24px',
  },
  profileImg: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.branding.gray[600]}`,
    fontSize: 56,
    width: 120,
    height: 120,
  },
  icon: {
    width: 32,
    height: 32,
    color: theme.branding.castiron,
  },
  input: {
    maxWidth: 250,
    marginBottom: 32,
  },
  link: {
    color: theme.branding.link,
    fontWeight: 600,
    lineHeight: '24px',
    fontSize: 14,
    maxWidth: 200,
  },
  value: {
    fontSize: 15,
    lineHeight: '24px',
    marginBottom: 32,
  },
  paragraph: {
    marginTop: 12,
    marginBottom: 24,
    fontSize: 14,
    color: theme.branding.graphite,
  },
  button: {
    backgroundColor: theme.branding.gray[600],
    color: theme.branding.error,
    minWidth: 182,
  },
  oIdentityButton: {
    borderRadius: 20,
    height: 40,
    width: 120,
    border: '1px solid #BE5F4F',
    color: '#BE5F4F',
    marginTop: 9,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    alignItems: 'start',
  },
  iconbtn: {
    color: theme.branding.link,
  },
}));

const AccountSettings: React.FC = () => {
  const [editMode, setEditMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { me } = useAppSelector(state => ({
    me: state.users.me,
  }));

  const handleClick = () => {
    dispatch(
      openModal({
        modalType: 'RESET_PASSWORD_MODAL',
        modalProps: {
          show: true,
        },
      }),
    );
  };

  const splittedDisplayName = me.displayName?.split(' ') || ['', ''];
  const initials = splittedDisplayName[0][0] + splittedDisplayName[1][0];

  const handleInputClick = async () => {
    setEditMode(false);
  };

  return (
    <div>
      <Helmet>
        <title>Account Settings | Castiron</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12} lg={8} className={classes.container}>
          <Typography variant="h2">Account Settings</Typography>
          <Typography variant="h3" className={classes.secondaryTitle}>
            My details
          </Typography>
          <Box className={classes.box}>
            <Avatar className={classes.profileImg} src={me.profileImg || undefined}>
              {!me.profileImg && initials}
            </Avatar>
            <div className={classes.labelContainer}>
              <Typography className={classes.label}>Full Name</Typography>
            </div>
            {editMode ? (
              <div className={classes.inputContainer}>
                <TextField value={inputValue} onChange={e => setInputValue(e.target.value)} className={classes.input} />
                <IconButton onClick={handleInputClick} className={classes.iconbtn}>
                  <Check />
                </IconButton>
              </div>
            ) : (
              <Typography className={classes.value}>{me.displayName}</Typography>
            )}
            <Link onClick={handleClick} role="button" tabIndex={0} className={classes.link} id="reset-password">
              Reset password
            </Link>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccountSettings;
