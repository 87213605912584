import { get } from '../index';

async function getCustomer(id) {
  try {
    const customer = await get('customers', id);
    return {
      ...customer,
      id,
    };
  } catch (err) {
    console.error('Error Get Customer: ', err);
  }
}

export default getCustomer;
