import { useSelector } from 'react-redux';
import trackEvent, { EventProperties, trackPage, trackUser } from '../events/trackEvent';
import _ from 'lodash';
import { UnregisterCallback } from 'history';
import { Customer } from '@castiron/domain';
import moment from 'moment';
import { Attribution } from '@castiron/domain';
import { useMemo } from 'react';
import { determineAttribution } from "../attribution";

export interface UnregisterHolder {
  unreg: UnregisterCallback;
}

interface ShopProperties {
  id?: string;
  websiteUrl?: string;
  businessName?: string;
  email?: string;
  tags?: string[];
  isICP?: boolean;
}

interface UserProperties {
  id?: string;
  email?: string;
  name?: string;
}

interface CartProperties {
  numItems?: number;
  total?: number;
  totalWithoutFees?: number;
}

interface CustomerProperties {
  id: string;
  email: string;
  name?: string;
  subscribed: boolean;
  hasEverSubscribed: boolean;
}

export interface DefaultEventProperties {
    attribution?: Attribution;
    shop?: ShopProperties;
    user?: UserProperties;
    cart?: CartProperties;
    experiments?: Record<string, string>,
    customer?: CustomerProperties
}

const createTrackEvent = (defaultProps: EventProperties = {}) => {
  return (eventName: string, props: EventProperties = {}) => {
    trackEvent(eventName, _.merge(props, defaultProps));
  };
};

const createTrackPage = (defaultProps: EventProperties = {}) => {
  return (name?: string, props: EventProperties = {}) => {
    trackPage(name, _.merge(props, defaultProps));
  };
};

const createTrackUser = (storedUserId?: string, defaultProps?: EventProperties) => {
  return (userId?: string, customer?: Customer, props: EventProperties = {}) => {
    let userTraits = {};
    if (customer) {
      userTraits = {
        name: `${customer.firstName} ${customer.lastName}`,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phone: customer.mobileNumber,
        address: {
          street: `${customer.addressOne} ${customer.addressTwo}`,
          city: customer.city,
          state: customer.state,
          postalCode: customer.postalCode,
        },
        email: customer.email,
        createdAt: moment.unix(customer.createdAt).toISOString(),
      };
    }

    trackUser(userId || storedUserId, _.merge(props, userTraits, defaultProps));
  };
};

export const useTracking = () => {
  const { shop, me, cart, experiments, customer } = useSelector(state => ({
    //@ts-ignore
    shop: state.shops.shop,
    //@ts-ignore
    me: state.users?.me,
    //@ts-ignore
    cart: state.cart,
    //@ts-ignore
    experiments: state.experiments?.active,
    //@ts-ignore
    customer: state.customers.customer
  }));

  const attribution = useMemo(() => determineAttribution(), []);

  const defaultProps: DefaultEventProperties = {
    attribution,
    experiments: experiments,
    shop: (shop)
        ? {
            id: shop.id,
            websiteUrl: shop.websiteUrl,
            businessName: shop.businessName,
            email: shop.email,
            tags: shop.tags,
            isICP: !!shop.tags?.includes('ICP')
        } : null,
    user: (me && me.uid)
        ? {
            id: me.uid,
            email: me.email,
            name: me.displayName
        } : null,
    cart: (cart?.hasLoaded)
        ? {
            numItems: cart.products?.map(p => p.quantity).reduce((total, quantity) => total + quantity, 0),
            total: cart.totals?.total,
            totalWithoutFees: cart.totals?.totalWithoutFees
        } : null,
    customer: (customer)
        ? {
          id: customer.id,
          email: customer.email,
          name: (customer.firstName) ? `${customer.firstName} ${customer.lastName}` : undefined,
          subscribed: customer.subscribed,
          hasEverSubscribed: customer.hasEverSubscribed
        } : null
  }

  return {
      trackEvent: createTrackEvent(defaultProps),
      trackPage: createTrackPage(defaultProps),
      trackUser: (defaultProps.user) ? createTrackUser(defaultProps.user.id, defaultProps.user) : createTrackUser(null, defaultProps),
  }

};

export default useTracking;
