import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { ReactNode, useState } from 'react';

interface Props extends AccordionProps {
  summary: ReactNode;
  subtitle?: ReactNode;
  defaultOpen?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '12px',
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '4px',
    boxShadow: 'none',
  },
  accordionSummaryTitle: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 700,
  },
  accordionSummarySubTitle: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 400,
    marginLeft: '42px',
    marginTop: '8px',
  },
  iconButton: {
    marginRight: '12px',
  },
  expanded: {
    margin: '0px',
    '&$expanded': {
      margin: '0px',
    },
  },
}));

const SimpleAccordion: React.FC<Props> = (props: Props) => {
  const { summary, children, subtitle, defaultOpen = false } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(defaultOpen);

  return (
    <Accordion
      {...props}
      classes={{ root: classes.root, expanded: classes.expanded }}
      onChange={(event: object, expanded: boolean) => setExpanded(expanded)}
      expanded={expanded}
    >
      <AccordionSummary>
        <Grid container direction="column">
          <Grid container alignItems="center">
            <IconButton className={classes.iconButton}>
              {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
            </IconButton>
            <Typography className={classes.accordionSummaryTitle}>{summary}</Typography>
          </Grid>
          {subtitle && !expanded && (
            <Typography className={classes.accordionSummarySubTitle} style={{ whiteSpace: 'pre-line' }}>
              {subtitle}
            </Typography>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default SimpleAccordion;
