import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      width: 200,
    },
  }),
);

type Props = {
  name: string;
  defaultValue?: string;
  onChange?: any;
  value?: any;
  disabled?: boolean;
};

export const DatePicker: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  return <TextField type="date" id="date" onChange={props.onChange} className={classes.textField} {...props} />;
};

export const DatePickerFormik: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <TextField
      type="date"
      id="date"
      onChange={(e): void => {
        const date = moment(e.target.value).unix();
        setFieldValue(field.name, date);
      }}
      value={props.value}
      disabled={props.disabled || false}
      className={classes.textField}
      {...props}
    />
  );
};
