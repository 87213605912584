import React, { useEffect, useContext } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Route, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import './App.scss';
import Layout from '../Layout/index';
// Routes
import AuthLayout from '../Layout/AuthLayout';
import Login from '../Auth/Login/index';
import SignUp from '../Auth/SignUp/SignUp';
import ForgotPasswordStart from '../Auth/ForgotPassword/ForgotPasswordStart';
import ForgotPasswordSubmit from '../Auth/ForgotPassword/ForgotPasswordSubmit';
import Dashboard from '../Dashboard';
// ------------------------------------------------------
import { getMyselfAction } from '../../store/reducers/users';
import RootModal from '../RootModal/RootModal';

import { FirebaseContext, handleAuthStateChanges } from '@castiron/castiron-firebase';
import AccountSettings from '../AccountSettings';
import { getShopAction, setRedirectUrl, setStripeRefresh } from '../../store/reducers/shops';
import { getAccountAction } from '../../store/reducers/accounts';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const FirebaseApp = useContext(FirebaseContext);
  const { me, modalProps, modalType } = useAppSelector(state => ({
    isShopLoading: state.shops.loading,
    shop: state.shops.shop,
    me: state.users.me,
    modalProps: state.modal.modalProps,
    modalType: state.modal.modalType,
  }));

  const getToFromQueryString = () => {
    const qs = queryString.parse(location.search);
    if (qs.to) {
      return qs.to;
    }

    return '';
  };

  const getStripeRefreshFromQueryString = () => {
    const qs = queryString.parse(location.search);
    if (qs.refresh) {
      return qs.refresh;
    }

    return '';
  };

  useEffect(() => {
    const redirectUrl = getToFromQueryString().toString();
    const isStripeRefresh = getStripeRefreshFromQueryString();
    dispatch(setRedirectUrl(redirectUrl));
    dispatch(setStripeRefresh(isStripeRefresh));
    const callback = safeUser => dispatch(getMyselfAction(safeUser));
    handleAuthStateChanges(FirebaseApp, callback);
  }, [dispatch]);

  useEffect(() => {
    if (me?.uid) {
      dispatch(getShopAction(me.uid));
      dispatch(getAccountAction(me.uid));
    }
  }, [me]);

  const authenticatedRoutes = (
    <>
      <Layout>
        <Route path="/account-settings" exact>
          <AccountSettings />
        </Route>
        <Route path="/" exact>
          <Dashboard />
        </Route>
        <Redirect to="/" />
      </Layout>
    </>
  );

  const unauthenticatedRoutes = (
    <>
      <Route path="/forgot-password/start" exact>
        <AuthLayout>
          <ForgotPasswordStart />
        </AuthLayout>
      </Route>
      <Route path="/forgot-password/submit" exact>
        <AuthLayout>
          <ForgotPasswordSubmit />
        </AuthLayout>
      </Route>
      <Route path="/sign-up" exact>
        <AuthLayout>
          <SignUp setUser={(userId: string): object => dispatch(getMyselfAction(userId))} />
        </AuthLayout>
      </Route>
      <Route path="/" exact>
        <AuthLayout>
          <Login setUser={(userId: string): object => dispatch(getMyselfAction(userId))} />
        </AuthLayout>
      </Route>
    </>
  );

  return (
    <>
      <RootModal modalType={modalType} modalProps={modalProps} />
      {me ? authenticatedRoutes : unauthenticatedRoutes}
    </>
  );
};

export default App;
