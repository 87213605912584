import React from 'react';
import { Button as MUIButton, ButtonProps as MUIButtonProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type ButtonProps = Override<
  MUIButtonProps,
  {
    variant?: 'text' | 'outlined' | 'contained';
  }
>;

const useStyles = makeStyles((theme: Theme) => ({
  button: {},
  borderOutlined: {
    borderColor: theme.branding.gray[400],
  },
}));

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { children, color, variant, className, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <MUIButton
      color={color || 'primary'}
      variant={variant || 'text'}
      className={`${classes.button}
      ${variant === 'outlined' && classes.borderOutlined}
      ${className}`}
      {...restOfProps}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
