import { query } from '../index';
import getAsset from '../assets/getAsset';

async function getShopsList() {
  try {
    const item = await query('shops');
    return item;
  } catch (err) {
    console.log('Error Get Shops List: ', err);
  }
}

export default getShopsList;
