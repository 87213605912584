import { combineReducers } from '@reduxjs/toolkit';
import users from './users';
import modal from './modalConductor';
import accounts from './accounts';
import products from './products';
import customers from './customers';
import shops from './shops';
import transactions from './transactions';
import assets from './assets';

const rootReducer = combineReducers({
  users,
  modal,
  accounts,
  shops,
  products,
  customers,
  transactions,
  assets,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
