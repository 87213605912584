import create from './createAsset';
import get from './getAsset';
import update from './updateAsset';
import queryByShopId from './queryAssetsByShopId';
import queryByCastIron from './queryAssetsByCastIron';

const api = {
  create,
  get,
  update,
  queryByShopId,
  queryByCastIron,
};

export default api;
