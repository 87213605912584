import getSafeUser from './getSafeUser';

function handleAuthStateChanges(FirebaseApp, callback) {
  FirebaseApp.auth().onAuthStateChanged(user => {
    console.debug('onAuthStateChanged: ', user);
    if (user) {
      // redux cannot parse the whole user object so map out what is needed
      const safeUser = getSafeUser(user);
      callback(safeUser);
    }
  });
}

export default handleAuthStateChanges;
