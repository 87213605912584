import { Grid, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { Text } from '../';

type Props = {
  content: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  afterPurchaseDetailsContainer: {
    backgroundColor: theme.palette.grey[200],
    padding: '16px 32px',
    borderRadius: 4,
    marginTop: 16,
  },
  asterick: {
    color: theme.branding.mint,
    left: -12,
    top: 4,
    fontSize: 20,
    position: 'absolute',
  },
}));

const AfterPurchaseDetails: React.FC<Props> = (props: Props) => {
  const { content } = props;
  const classes = useStyles();

  if (!content) return null;

  return (
    <Grid className={classes.afterPurchaseDetailsContainer}>
      <Text presetFont="txt--xs" lineHeight={16} style={{ whiteSpace: 'pre-line', position: 'relative' }}>
        <span className={classes.asterick}>*</span> {content}
      </Text>
    </Grid>
  );
};

export default AfterPurchaseDetails;
