import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Container, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';

type Props = {
  value: string;
  onChange: (newTime: string) => void;
};

type TimeUnit = 'hour' | 'minutes' | 'period';

const useStyles = makeStyles(() =>
  createStyles({
    adornment: { marginLeft: 18, marginBottom: 5 },
    adornmentWrapper: { position: 'absolute' },
    inputRoot: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
    },
    popper: {
      minWidth: 60,
    },
  }),
);

const hours = Array.from({ length: 12 }, (e, i) => (i < 9 ? `0${i + 1}` : (i + 1).toString()));
const minutes = Array.from({ length: 60 }, (e, i) => (i < 10 ? `0${i}` : i.toString()));
const period = ['AM', 'PM'];

const TimePicker: React.FC<Props> = (props: Props) => {
  // Value in 'hh:mm A' format, e.g. '01:24 PM'
  const { value } = props;
  const hourValue = value.substring(0, 2);
  const minutesValue = value.substring(3, 5);
  const periodValue = value.substring(6, 8);
  const classes = useStyles();

  const handleChange = (timeUnit: TimeUnit, newTimeValue: string): void => {
    // Create new string containing new inputted time in 'hh:mm A' format
    // by replacing the part of the old time value containing the modified time
    let newTime: string;

    if (timeUnit === 'hour') newTime = newTimeValue + value.substring(2);
    if (timeUnit === 'minutes') newTime = value.substring(0, 3) + newTimeValue + value.substring(5);
    if (timeUnit === 'period') newTime = value.substring(0, 6) + newTimeValue;

    props.onChange(newTime);
  };

  return (
    <Container className={classes.container}>
      <Autocomplete
        classes={{
          inputRoot: classes.inputRoot,
          popper: classes.popper,
        }}
        style={{ width: 40 }}
        disableClearable
        forcePopupIcon={false}
        size="small"
        fullWidth={false}
        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment className={classes.adornmentWrapper} position="end">
                  <Typography className={classes.adornment}>:</Typography>
                </InputAdornment>
              ),
            }}
          />
        )}
        value={hourValue}
        inputValue={hourValue}
        getOptionLabel={(option): string => option}
        onChange={(e, newValue): void => handleChange('hour', newValue)}
        options={hours}
      />
      <Autocomplete
        classes={{
          inputRoot: classes.inputRoot,
          popper: classes.popper,
        }}
        style={{ width: 40 }}
        disableClearable
        forcePopupIcon={false}
        size="small"
        fullWidth={false}
        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} />}
        value={minutesValue}
        inputValue={minutesValue}
        getOptionLabel={(option): string => option}
        onChange={(e, newValue): void => handleChange('minutes', newValue)}
        options={minutes}
      />
      <Autocomplete
        classes={{
          inputRoot: classes.inputRoot,
          popper: classes.popper,
        }}
        style={{ width: 40 }}
        disableClearable
        forcePopupIcon={false}
        size="small"
        fullWidth={false}
        renderInput={(params: AutocompleteRenderInputParams): JSX.Element => <TextField {...params} />}
        value={periodValue}
        inputValue={periodValue}
        getOptionLabel={(option): string => option}
        onChange={(e, newValue): void => handleChange('period', newValue)}
        options={period}
      />
    </Container>
  );
};

export default TimePicker;
