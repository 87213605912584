export interface EventProperties {
  [key: string]: any;
}

const SLACK_WHITELIST_EVENTS = ['Shop Signup', 'First Product Created'];

function trackSegment(eventName: string, props?: EventProperties): void {
  //@ts-ignore
  if (typeof analytics === 'undefined') return;

  //@ts-ignore
  console.debug(`Sending Segment Event [${eventName}]`, props);

  try {
    //@ts-ignore
    analytics
      .track(eventName, props, {
        integrations: {
          All: true,
          Slack: SLACK_WHITELIST_EVENTS.includes(eventName),
        },
      })
      .then(e => {
        if (e && e.logger) {
          const errors = e.logger.logs.filter(l => l.level === 'error');
          if (errors) {
            errors.forEach(e => {
              console.error('Error sending event to Segment', e.extras.error);
            });
          }
        }
      })
      .catch(e => console.error('Error tracking event', e));
  } catch (e) {
    console.debug('Error calling track', e);
  }
}

export default function trackEvent(eventName: string, props?: EventProperties): void {
  trackSegment(eventName, props);
}

export function trackPage(pageName?: string, props?: EventProperties): void {
  //@ts-ignore
  if (typeof analytics === 'undefined') return;
  if (pageName) {
    //@ts-ignore
    analytics.page(pageName, props);
  } else {
    //@ts-ignore
    analytics.page(props);
  }
}

export function trackUser(id?: string, props: EventProperties = {}): void {
  //@ts-ignore
  if (typeof analytics === 'undefined') return;

  if (id) {
    //@ts-ignore
    analytics.identify(id, props);
  } else {
    //@ts-ignore
    analytics.identify(props);
  }
}
