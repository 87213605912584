import { query } from '../index';

async function queryCustomersByShopId(shopId) {
  try {
    console.log('queryCustomersByShopId reached: ', shopId);
    const comparisons = [
      {
        prop: 'shopId',
        comparison: '==',
        value: shopId,
      },
    ];
    return await query('customers', comparisons);
  } catch (err) {
    console.log('Error Get Customer: ', err);
  }
}

export default queryCustomersByShopId;
