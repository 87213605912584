import FirebaseApp from '../config/initFirebase';

async function signOut(callback = null) {
  await FirebaseApp.auth().signOut();

  // this may be a redirect or an action dispatch to clear redux
  if (callback) callback();

  return;
}

export default signOut;
