import FirebaseApp from '../config/initFirebase';
import updateUserProfile from './updateUserProfile';

// DOCS: https://firebase.google.com/docs/auth/web/manage-users?authuser=1#update_a_users_profile
const createUserWithEmailAndPassword = async function(email, password, name) {
  const user = await FirebaseApp.auth().createUserWithEmailAndPassword(email, password);
  console.debug('user after create', user);
  console.debug('create user with email and password name: ', name);
  const updateResult = await updateUserProfile(name);
  console.debug('updateResult: ', updateResult);
  return user;
};

export default createUserWithEmailAndPassword;
