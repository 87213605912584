import { Checkbox as MUICheckbox, FormControlLabel, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';

type Props = {
  label?: ReactNode;
  name?: string;
  checked?: boolean;
  checkboxClass?: string;
  onChange?: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    alignItems: 'flex-start',
    '& .MuiCheckbox-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiCheckbox-colorPrimary.Mui-checked': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  checkbox: {
    paddingTop: 0,
    borderColor: theme.palette.secondary.light,
  },
}));

const Checkbox: React.FC<Props> = (props: Props) => {
  const { label, name, checked, checkboxClass, onChange } = props;
  const classes = useStyles();

  return (
    <FormControlLabel
      className={`${classes.formControl} ${checkboxClass}`}
      labelPlacement="end"
      onChange={onChange}
      control={
        <MUICheckbox disableTouchRipple focusRipple className={classes.checkbox} color="primary" checked={checked} />
      }
      label={label}
      name={name}
    />
  );
};

export default Checkbox;
