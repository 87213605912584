import React from 'react';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HeaderText } from '../';
import { HeaderTextProps } from '../HeaderText';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type PageHeaderProps = Override<HeaderTextProps, {}>;

const useStyles = makeStyles((theme: Theme) => ({}));

const PageHeader: React.FC<PageHeaderProps> = (props: PageHeaderProps) => {
  const { children, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <HeaderText presetFont="heading--l" fontSize={36} {...restOfProps}>
      {children}
    </HeaderText>
  );
};

export default PageHeader;
