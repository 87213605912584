import firebase from 'firebase';
import _firestore from '@google-cloud/firestore';
import moment from 'moment';
import { BaseDocument, BaseRepository, FieldFunctions } from '../base/repository';
import { removeEmpty } from '../_lib/removeEmpty';
import {OrderType} from "../order";

export interface SendGridConfig {
  contactListId: string;
  unsubscribeGroupId: number;
  senderId: number;
}

export type TierName = 'free' | 'founder';
export type BusinessStage =
  | 'brand-new'
  | 'no-online-ordering'
  | 'selling-online-unhappy'
  | 'compairing'
  | 'just-looking';

export type TakeRateLevelType = OrderType & 'all';

export interface TakeRateLevel {
  type: TakeRateLevelType;
  orderTotalMinimum?: number;
  castironTakeRate: number;
}

export interface Tier {
  name: TierName;
  castironTakeRate: number;
  takeRateLevels?: TakeRateLevel[];
  payoutFrequency: 'daily' | 'weekly' | 'monthly';
}

export interface AccountMetrics {
  numSales?: number;
  totalRevenue?: number;
  lastSaleDate?: number;
  numCustomers?: number;
  numActiveProducts?: number;
}

export interface Account extends BaseDocument<Account> {
  lastStripeConnectionAttempt?: number;
  stripeAccountId?: string;
  isReady?: boolean;
  sendGridConfig?: SendGridConfig;
  tier: Tier;
  businessStage?: BusinessStage;
  metrics?: AccountMetrics;
  orderNumberCounter: number;
}

const FREE: Tier = {
  name: 'free',
  castironTakeRate: 10,
  payoutFrequency: 'weekly',
};

const FOUNDER: Tier = {
  name: 'founder',
  castironTakeRate: 5,
  payoutFrequency: 'daily',
};

export const Tiers = { FREE, FOUNDER };

export class AccountRepository extends BaseRepository<Account> {
  constructor(firestore: firebase.firestore.Firestore | _firestore.Firestore, fieldFunctions?: FieldFunctions) {
    super(firestore, 'accounts', fieldFunctions);
  }

  public async findByStripeId(stripeAccountId: string): Promise<Account | null> {
    const results = await this.find({
      where: [{ field: 'stripeAccountId', operator: '==', value: stripeAccountId }],
    });

    return this.firstOrNull(results);
  }

  public async findBySendGridUnsubscribeGroup(unsubscribeGroupId: number): Promise<Account | null> {
    const results = await this.find({
      where: [{ field: 'sendGridConfig.unsubscribeGroupId', operator: '==', value: unsubscribeGroupId }],
    });

    return this.firstOrNull(results);
  }

  public async findArtisanActiveAccounts(): Promise<Account[]> {
    const sixtyDaysAgo = moment()
      .subtract(60, 'days')
      .unix();
    return this.find({
      where: [{ field: 'metrics.numSales', operator: '>=', value: 10 }],
    }).then(accounts =>
      accounts.filter(a => a.metrics.lastSaleDate >= sixtyDaysAgo && a.metrics.numActiveProducts >= 2),
    );
  }

  public async updateMetrics(id: string, metrics: AccountMetrics) {
    return this.updateProps(
      id,
      removeEmpty({
        'metrics.lastSaleDate': metrics.lastSaleDate,
        'metrics.numCustomers': metrics.numCustomers && this.fieldFunctions.increment(metrics.numCustomers),
        'metrics.numProducts': metrics.numActiveProducts && this.fieldFunctions.increment(metrics.numActiveProducts),
        'metrics.numSales': metrics.numSales && this.fieldFunctions.increment(metrics.numSales),
        'metrics.totalRevenue': metrics.totalRevenue && this.fieldFunctions.increment(metrics.totalRevenue),
      }),
    );
  }
}
