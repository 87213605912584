import React from 'react';
import './Header.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useHistory } from 'react-router-dom';
import { signOutAction } from '../../../store/reducers/users';
import {
  Button,
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Link,
  Popover,
  Badge,
  Avatar,
  ButtonBase,
} from '@material-ui/core';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Logo, SettingsIcon, LogoutIcon } from '@castiron/components';
import SvgIcon from '@castiron/components/src/SvgIcon';
import MenuIcon from '../../../assets/img/menu.svg';
import { ExpandLess, ExpandMore, OpenInNew } from '@material-ui/icons';
import NotificationsPopover from './NotificationsPopover';

type Props = {
  setDrawerOpen: (status: boolean) => void;
};

const Header: React.FC<Props> = (props: Props) => {
  const { setDrawerOpen } = props;
  const { me, shop } = useAppSelector(state => ({
    me: state.users.me,
    shop: state.shops.shop,
  }));
  const history = useHistory();

  const dispatch = useAppDispatch();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      avatar: {
        border: `1px solid ${theme.branding.gray[600]}`,
        height: 36,
        width: 36,
        fontSize: 16,
        [theme.breakpoints.up('md')]: {
          height: 56,
          width: 56,
          fontSize: 22,
          '& img': {
            borderRadius: '50%',
          },
        },
        backgroundColor: theme.palette.primary.main,
        // '& .MuiButton-label': {
        //   borderRadius: '50%',
        //   overflow: 'hidden',
        // },
      },
      userActions: {
        display: 'flex',
        alignItems: 'center',
      },
      userActionsButton: {
        padding: 0,
        '&:hover': {
          background: 'none',
        },
      },
      userActionsPopup: {
        '& .MuiPopover-paper': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          '& .MuiButton-sizeLarge': {
            border: '2px solid #fff',
            width: '100%',
            justifyContent: 'flex-start',
          },
        },
      },
      toolbar: {
        [theme.breakpoints.up('md')]: {
          minHeight: 100,
          paddingLeft: 56,
        },
        [theme.breakpoints.down('sm')]: {
          height: 58,
          borderBottom: '1px solid #EEEEEE',
        },
        color: theme.branding.castiron,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
      signOutButton: {
        marginLeft: 'auto',
      },
      icon: {
        width: 40,
        height: 40,
      },
      rightSideContainerMobile: {
        display: 'flex',
        gap: 8,
      },
      bellButton: {
        position: 'relative',
        top: -7,
      },
      bellIcon: {
        fontSize: 36,
        color: theme.branding.castiron,
      },
      userName: {
        fontSize: 15,
        textAlign: 'right',
        lineHeight: 1.3,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      storeName: {
        whiteSpace: 'nowrap',
        marginRight: 10,
        maxWidth: '80%',
        display: 'inline-block',
        fontSize: 13,
        color: theme.palette.text.secondary,
        position: 'relative',
        '&:hover': {
          color: theme.palette.text.secondary,
        },
      },
      storeTitleContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      linkIcon: {
        color: theme.palette.text.secondary,
        // position: 'absolute',
        // right: -13,
        // top: 2,
      },
      search: {
        background: theme.branding.ghostWhite,
        borderRadius: 8,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          '&:hover': {
            border: 'none',
          },
        },
        '& .MuiOutlinedInput-input': {
          paddingTop: 14.5,
          paddingBottom: 14.5,
        },
      },
      menuIconContainer: {
        color: theme.branding.castiron,
        position: 'relative',
        right: 12,
      },
    }),
  );
  const classes = useStyles();

  const BellBadge = withStyles((theme: Theme) =>
    createStyles({
      badge: {
        right: 10,
        top: 10,
        border: `2px solid ${theme.palette.background.paper}`,
        borderRadius: '50%',
        background: theme.branding.callout,
        padding: 4,
      },
    }),
  )(Badge);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccountClick = () => {
    history.push('/account-settings');
    setAnchorEl(null);
  };

  const handleSignOutCick = (): void => {
    history.push('/');
    dispatch(signOutAction());
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const splittedDisplayName = me && me.displayName ? me.displayName.split(' ').filter(word => word) : '';
  const initials = !!splittedDisplayName
    ? `${splittedDisplayName[0][0]}${splittedDisplayName[1] ? splittedDisplayName[1][0] : ''}`
    : '||';

  return (
    <AppBar elevation={0} color="transparent" position="relative">
      <Toolbar className={classes.toolbar}>
        <Hidden mdUp>
          <IconButton className={classes.menuIconContainer} onClick={(): void => setDrawerOpen(true)}>
            <SvgIcon className={classes.icon}>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          <Logo width={147} />
          <div className={classes.rightSideContainerMobile}>
            <ButtonBase onClick={handleClick}>
              <Avatar src={me.profileImg || undefined} className={classes.avatar}>
                {!me.profileImg && initials}
              </Avatar>
            </ButtonBase>
          </div>
        </Hidden>
        <Hidden smDown>
          <div className={classes.userActions}>
            <span className={classes.userName}>
              {me.displayName}
              <br />
              <div className={classes.storeTitleContainer}>
                <Link
                  href={`https://shop.castiron.me/${shop?.websiteUrl}`}
                  target="_blank"
                  className={classes.storeName}
                >
                  {shop?.businessName}
                </Link>
                <OpenInNew className={classes.linkIcon} fontSize="inherit" />
              </div>
            </span>
            <Button onClick={handleClick} className={classes.userActionsButton} disableRipple>
              <Avatar src={me.profileImg || undefined} className={classes.avatar}>
                {!me.profileImg && initials && initials.toUpperCase()}
              </Avatar>
              {open ? <ExpandLess /> : <ExpandMore />}
            </Button>
          </div>
        </Hidden>
        <NotificationsPopover anchorEl={notificationAnchorEl} onClose={handleNotificationClose} />
        <Popover
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          className={classes.userActionsPopup}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Button onClick={handleAccountClick} startIcon={<SettingsIcon />}>
            Account Settings
          </Button>
          <Button startIcon={<LogoutIcon />} className={classes.signOutButton} onClick={handleSignOutCick}>
            Sign out
          </Button>
        </Popover>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
