import { query } from '../index';

async function queryAccountByWebsiteUrl(websiteUrl) {
  try {
    console.log('queryAccountByWebsiteUrl reached: ', websiteUrl);
    const comparisons = [
      {
        prop: 'websiteUrl',
        comparison: '==',
        value: websiteUrl,
      },
    ];
    return await query('accounts', comparisons);
  } catch (err) {
    console.log('Error Get Account: ', err);
  }
}

export default queryAccountByWebsiteUrl;
