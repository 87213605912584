import create from './createTransaction';
import get from './getTransaction';
import update from './updateTransaction';
import queryByShopId from './queryTransactionsByShopId';

const api = {
  create,
  get,
  update,
  queryByShopId,
};

export default api;
