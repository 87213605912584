import { query } from '../index';
import getAsset from '../assets/getAsset';

async function queryShopByWebsiteUrl(websiteUrl) {
  try {
    console.log('queryShopByWebsiteUrl reached: ', websiteUrl);
    const comparison = [
      {
        prop: 'websiteUrl',
        comparison: '==',
        value: websiteUrl,
      },
    ];
    const items = await query('shops', comparison);

    return items;
  } catch (err) {
    console.log('Error Get Shop: ', err);
  }
}

export default queryShopByWebsiteUrl;
