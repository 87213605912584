import { set } from '..';

async function createCustomer(data) {
  console.log('createCustomer: ', data);
  const customer = {
    ...data,
  };
  const result = await set('customers', customer);
  console.log('result of createCustomer: ', result);
  return result;
}

export default createCustomer;
