import { get } from '../index';
import getAsset from '../assets/getAsset';

async function getShop(id) {
  try {
    const item = await get('shops', id);
    return item;
  } catch (err) {
    console.log('Error Get Shop: ', err);
  }
}

export default getShop;
