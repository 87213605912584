import React from 'react';
import { useAppDispatch } from '../../../hooks';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import { loginWithGoogle, loginWithFacebook, loginWithTwitter, shops, accounts } from '@castiron/castiron-firebase';
import { getShopAction, createShopAction } from '../../../store/reducers/shops';
import { getAccountAction, createAccountAction } from '../../../store/reducers/accounts';

const useStyles = makeStyles(theme => ({
  socialButton: {
    textAlign: 'center',
    '& button': {
      border: 'none',
      borderRadius: '50%',
      padding: '0',
      height: '75px',
      width: '75px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.15)',
      '& img': {
        maxWidth: '46px',
      },
    },
  },
  socialContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 32,
  },
  disabledButton: {
    opacity: 0.5,
  },
}));

const SocialLoginButtons = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const accountExists = async userId => {
    // user id
    const result = await accounts.get(userId);
    return !!result;
  };

  const shopExists = async userId => {
    // user id
    const result = await shops.get(userId);
    return !!result;
  };

  const loginWithProvider = async userId => {
    // check for account
    const hasAccount = await accountExists(userId);
    // check for shop
    const hasShop = await shopExists(userId);

    hasAccount ? dispatch(getAccountAction(userId)) : dispatch(createAccountAction({}));
    hasShop ? dispatch(getShopAction(userId)) : dispatch(createShopAction({}));
  };

  const loginWithGoogleAction = async () => {
    const result = await loginWithGoogle(undefined);
    await loginWithProvider(result.uid);
  };
  const loginWithFacebookAction = async () => {
    const result = await loginWithFacebook(undefined);
    await loginWithProvider(result.uid);
  };
  const loginWithTwitterAction = async () => {
    const result = await loginWithTwitter();
    await loginWithProvider(result.uid);
  };

  return (
    <div className={classes.socialContainer}>
      <div className={classes.socialButton}>
        <Button variant="outlined" onClick={loginWithFacebookAction}>
          <img
            src="/assets/img/facebook-f-logo.svg"
            alt="F
            acebook logo"
          />
        </Button>
      </div>
      <div className={classes.socialButton}>
        <Button variant="outlined" onClick={loginWithTwitterAction}>
          <img src="/assets/img/twitter-bird-logo.svg" style={{ maxWidth: '44px' }} alt="Twitter logo" />
        </Button>
      </div>
      <div className={classes.socialButton}>
        <Button variant="outlined" onClick={loginWithGoogleAction}>
          <img src="/assets/img/google-g-logo.svg" style={{ maxWidth: '42px' }} alt="Google logo" />
        </Button>
      </div>
    </div>
  );
};

export default SocialLoginButtons;
