import { set } from '..';
import getAsset from '../assets/getAsset';

async function updateProduct(item) {
  try {
    // Filter out empty values
    Object.keys(item).map(key => {
      if (item[key] === '') delete item[key];
    });
    // TODO: Refactor Table names to constants
    const product = await set('products', item, item.id);
    // if (product.image) {
    //   product.imageObj = await getAsset(product.image);
    // }
    console.log('result of updateProduct: ', product);
    return product;
  } catch (err) {
    console.log('Error Update Product: ', err);
  }
}

export default updateProduct;
