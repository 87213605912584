import create from './createCustomer';
import get from './getCustomer';
import update from './updateCustomer';
import queryByShopId from './queryCustomersByShopId';

const api = {
  create,
  get,
  update,
  queryByShopId,
};

export default api;
