import { set } from '..';
import getCurrentUser from '../../auth/getCurrentUser';

async function createAccount() {
  const user = getCurrentUser();
  console.log('createUserAccount: ', user);
  // TODO: Refactor Table names to constants
  const account = {
    id: user.uid,
    // TODO: CreatedDate
  };
  const result = await set('accounts', account, user.uid);
  console.log('result of createAccount: ', result);
  return result;
}

export default createAccount;
