import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import SignUpForm from './SignUpForm';
import '../Auth.scss';
import { Typography, Grid } from '@material-ui/core';

type Props = {
  setUser: Function;
};

const SignUp: React.FC<Props> = (props: Props) => {
  const { myself } = useAppSelector(state => ({
    myself: state.users.me,
  }));

  if (myself) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <SignUpForm setUser={props.setUser} />
      <Grid container justify="center" className="auth-footer">
        <Grid item xs={12}>
          <Typography align="center">
            Already have an account? <Link to="/">Log in</Link>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUp;
