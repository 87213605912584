import React, { ReactNode } from 'react';
import { useAppDispatch } from '../../../hooks';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Spinner from '../../Spinner';
import { Forms, Input } from '@castiron/components';
import { Box, Grid, Link, Theme, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { createUserWithPassword } from '@castiron/castiron-firebase';
import Button from '@castiron/components/src/Button/Button';
import { useHistory } from 'react-router';
import { createShopAction } from '../../../store/reducers/shops';
import { createAccountAction } from '../../../store/reducers/accounts';
import { getMyselfAction } from '../../../store/reducers/users';

const { SubmissionError } = Forms;

type Props = {
  setUser: Function;
};

const useStyles = makeStyles((theme: Theme) => ({
  inputContainer: {
    marginTop: -30,
  },
  termsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
    marginBottom: 30,
  },
  termsLink: {
    color: theme.palette.text.secondary,
  },
}));

const SignUpForm: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    email: yup
      .string()
      .email()
      .required('Emai is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(6),
    passwordConfirm: yup
      .string()
      .required('Password Confirmation is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  });

  // eslint-disable-next-line
  const onSubmit = async (values: any, { setSubmitting, setFieldError }: any): Promise<any> => {
    try {
      // TODO: Pass in first and last name here.
      const result = await createUserWithPassword(values.email, values.password, {
        firstName: values.firstName,
        lastName: values.lastName,
      });

      const user = {
        email: result.user.email,
        uid: result.user.uid,
        displayName: result.user.displayName,
      };

      dispatch(getMyselfAction(user));
      dispatch(createAccountAction({}));
      dispatch(createShopAction({}));
      setSubmitting(false);
      history.push('/setup');
    } catch (e) {
      setSubmitting(false);
      setFieldError('general', e.message);
    }
  };

  return (
    <Formik
      initialValues={{ firstName: '', lastName: '', email: '', password: '', passwordConfirm: '' }}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {({ isSubmitting, errors, touched }): ReactNode => (
        <>
          <Spinner show={isSubmitting} size={'fullscreen'} />
          <>
            {errors?.general && <SubmissionError msg={errors.general} />}
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Input
                    type="text"
                    error={touched.firstName && errors.firstName}
                    name="firstName"
                    label="First name"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input type="text" error={touched.firstName && errors.firstName} name="lastName" label="Last name" />
                </Grid>
                <Grid className={classes.inputContainer} item xs={12}>
                  <Input type="email" error={touched.email && errors.email} name="email" label="Your email" />
                </Grid>
                <Grid className={classes.inputContainer} item xs={6}>
                  <Input label="Password" error={touched.password && errors.password} type="password" name="password" />
                </Grid>
                <Grid className={classes.inputContainer} item xs={6}>
                  <Input
                    type="password"
                    error={touched.passwordConfirm && errors.passwordConfirm}
                    label="Confirm password"
                    name="passwordConfirm"
                  />
                </Grid>
                <Grid container item justify="center">
                  <Grid item>
                    <Box my={3}>
                      <Button variant="contained" type="submit">
                        SIGN UP WITH EMAIL
                      </Button>
                    </Box>
                  </Grid>
                  <Grid container item justify="center">
                    <div className={classes.termsContainer}>
                      <Typography align="center" variant="caption" component="p" color="textSecondary">
                        By signing up, you agree to our{' '}
                        <Link
                          className={classes.termsLink}
                          target="_blank"
                          href="https://www.castiron.me/terms-conditions"
                        >
                          Terms of Service
                        </Link>
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </>
        </>
      )}
    </Formik>
  );
};

export default SignUpForm;
