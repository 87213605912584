import { query } from '../index';

async function queryAssetsByShopId(shopId) {
  try {
    console.log('queryAssetsByShopId reached: ', shopId);
    const comparisons = [
      {
        prop: 'shopId',
        comparison: '==',
        value: shopId,
      },
    ];
    const items = await query('assets', comparisons);
    // Populate Categories
    return items ? items : [];
  } catch (err) {
    console.log('Error Get Asset: ', err);
  }
}

export default queryAssetsByShopId;
