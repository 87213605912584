import getCurrentUser from './getCurrentUser';

async function updateUserProfile(data) {
  try {
    // bail early if nothing is passed in.
    console.debug('updateUser Profile : ', data);
    if (!data) {
      console.debug('no data in updateUserProfile');
      return;
    }
    const user = getCurrentUser();
    console.debug('getCurrentUser user: ', user);

    if (!user) {
      console.debug('no user profile to update: ');
      return;
    }

    const newUser = {
      //   firstName: data.firstName || '',
      //   lastName: data.lastName || '',
      displayName: `${data.firstName} ${data.lastName}`,
    };
    console.debug('newUser: ', newUser);
    const result = await user.updateProfile(newUser);
    console.debug('result of updateUserProfile: ', updateUserProfile);
    return result;
  } catch (err) {
    console.debug('Error updating user profile: ', err);
  }
}

export default updateUserProfile;
