import React from "react";
import { Grid, makeStyles, Theme, Typography } from "@material-ui/core";

interface Props {
  items: String[];
};

const useStyles = makeStyles((theme: Theme) => ({
  orderedListItem: {
    margin: '4px 0',
  },
  orderedListIndex: {
    margin: '0 8px',
    color: theme.branding.darkGray,
    fontSize: '12px',
  },
  orderedListContent: {
    fontSize: '14px',
    overflowWrap: 'break-word',
  },
}));

const OrderedList: React.FC<Props> = (props: Props) => {
  const { items } = props;

  const classes = useStyles();

  return (
    <Grid container>
      {
        items.map((item, index) => {
          return (
            <Grid item container xs={12} className={classes.orderedListItem}>
              <Grid item xs={1}>
                <Typography className={classes.orderedListIndex}>{index + 1}</Typography>
              </Grid>
              <Grid item xs={11}>
                <Typography className={classes.orderedListContent}>{item}</Typography>
              </Grid>
            </Grid>
          );
        })
      }
    </Grid>
  );
};

export default OrderedList;