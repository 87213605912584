import { set } from '..';

async function createProduct(data) {
  console.log('createProduct: ', data);
  const product = {
    ...data,
  };
  const result = await set('products', product);
  console.log('result of createProduct: ', result);
  return result;
}

export default createProduct;
