import React, { ReactNode } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

export type PresetFonts = 'heading--l' | 'heading--m' | 'heading--s' | 'heading--xs';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type HeaderTextProps = Override<
  TypographyProps,
  {
    fontSize?: number;
    bold?: boolean;
    fontWeight?: number;
    lineHeight?: number;
    color?: string;
    children?: ReactNode;
    presetFont?: PresetFonts;
  }
>;

const useStyles = makeStyles((theme: Theme) => ({
  'heading--xs': { fontSize: 16, lineHeight: '36px', fontWeight: 700 },
  'heading--s': { fontSize: 20, lineHeight: '36px', fontWeight: 700 },
  'heading--m': { fontSize: 24, lineHeight: '36px', fontWeight: 700 },
  'heading--l': { fontSize: 37, lineHeight: '42px', fontWeight: 800 },
}));

const HeaderText: React.FC<HeaderTextProps> = (props: HeaderTextProps) => {
  const { presetFont, fontSize, bold, lineHeight, color, fontWeight, className, style, ...restOfProps } = props;
  const classes = useStyles();

  return (
    <Typography
      className={`${presetFont ? classes[presetFont] : ''} ${className ? className : ''}`}
      style={{
        ...style,
        fontSize,
        lineHeight: lineHeight ? `${lineHeight}px` : undefined,
        fontWeight: fontWeight ? fontWeight : bold ? 700 : undefined,
        color,
      }}
      {...restOfProps}
    >
      {props.children}
    </Typography>
  );
};

export default HeaderText;
