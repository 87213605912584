import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { List, makeStyles, Paper, Typography, Collapse, ButtonBase } from '@material-ui/core';
import {
  AnalyticsIcon,
  BulbIcon,
  CommunityIcon,
  CustomersIcon,
  FaqsIcon,
  HomeIcon,
  Logo,
  MegaphoneIcon,
  OrdersIcon,
  ProductsIcon,
  StoreIcon,
  ToolboxIcon,
} from '@castiron/components';
import NavItem from './NavItem/NavItem';
// import Tips from '../Tips/Tips';

type Props = {
  role: string;
};

const useStyles = makeStyles(theme => ({
  logoContainer: {
    padding: '16px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 40,
  },
  betaTag: {
    backgroundColor: '#FC2626',
    borderRadius: 4,
    position: 'absolute',
    top: -3,
    right: 11,
    fontSize: 9,
    fontWeight: 700,
    color: theme.palette.common.white,
    padding: 4,
    paddingTop: 6,
  },
  divider: {
    height: 1,
    background: 'radial-gradient(circle, rgba(244,244,244,1) 0%, rgba(255,255,255,1) 100%)',
    margin: 28,
  },
}));

const Nav: React.FC<Props> = () => {
  const classes = useStyles();
  const [storeExpanded, setStoreExpanded] = React.useState(false);
  const history = useHistory();

  const onLogoClick = () => {
    history.push('/');
  };

  return (
    <>
      <ButtonBase focusRipple onClick={onLogoClick} className={classes.logoContainer}>
        <Logo width={164} style={{ marginRight: 19 }} />
      </ButtonBase>
      <div className={classes.betaTag}>BETA</div>
      <List disablePadding>
        <NavItem label="Home" path="/" Icon={HomeIcon} />
        <Collapse in={storeExpanded} timeout="auto" unmountOnExit>
          <NavItem label="Website" path="/store/website" />
          <NavItem label="Payments" path="/store/payments" />
          <NavItem label="Coupons" path="/store/coupons" />
        </Collapse>
      </List>
      <div className={classes.divider}>&nbsp;</div>
    </>
  );
};

export default Nav;
