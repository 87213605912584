import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { CheckCircleSolidIcon, TextProps, Text } from '../';

const useStyles = makeStyles((theme: Theme) => ({
  default: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    color: theme.branding.mint,
  },
  checkIcon: {
    marginRight: 8,
    height: 14,
    width: 14,
  },
}));

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type AddedTagProps = Override<
  TextProps,
  {
    added?: boolean;
    size?: number;
  }
>;

// Added tag
const AddedTag: React.FC<AddedTagProps> = (props: AddedTagProps) => {
  const { className, size, added, ...restOfProps } = props;

  const classes = useStyles();

  if (!added) return null;

  return (
    <Text presetFont="txt--s" className={`${classes.default} ${className}`} fontSize={size || 14} {...restOfProps}>
      <CheckCircleSolidIcon className={classes.checkIcon} style={{ height: size || 14, width: size || 14 }} /> Added
    </Text>
  );
};

export default AddedTag;
