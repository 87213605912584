import { set } from '..';

async function updateShop(item) {
  try {
    // Filter out empty values
    // Object.keys(item).map((key) => {
    //     if (!item[key]) delete item[key];
    // });
    // TODO: Refactor Table names to constants
    if (item.websiteUrl) {
      item.websiteUrl = item.websiteUrl.toLowerCase();
    }
    const result = await set('shops', item, item.id);
    console.log('result of updateShop: ', result);
    return result;
  } catch (err) {
    console.log('Error Update Shop: ', err);
  }
}

export default updateShop;
