import { get } from '../index';
import getCustomer from '../customers/getCustomer';

async function getTransaction(id) {
  try {
    const transaction = await get('transactions', id);
    const customerObj = await getCustomer(transaction.customer);
    return {
      ...transaction,
      id,
      customerObj,
    };
  } catch (err) {
    console.log('Error Get Transaction: ', err);
  }
}

export default getTransaction;
