import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Divider, Grid, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";

interface Props {
  expanded?: boolean;
  showEdit?: boolean;
  title: string;
  onEditClick?: () => void;
  className?: string;
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid ${theme.branding.gray[400]}`,
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    marginBottom: 24,
  },
  innerContainer: {
    padding: '12px 32px 35px',
  },
  header: {
    padding: '20px 32px',
    borderBottom: `solid ${theme.branding.ghostWhite} 1px`
  },
  body: {
    padding: '12px 32px 35px',
  },
  edit: {
    textAlign: 'end',
  },
  editButton: {
    color: theme.palette.primary.light,
  },
}));

const Section: React.FC<Props> = (props: Props) => {
  const { expanded, showEdit, title, onEditClick, children, className } = props;
  const classes = useStyles();

  const isExpanded = expanded === undefined ? true : expanded;

  return <Grid container className={clsx(classes.container, className)} direction="column" component="section">
    <Grid item container className={classes.header} direction="row">
      <Grid item xs={10}>
        <Typography variant="h3">{title}</Typography>
      </Grid>
      {showEdit && (
        <Grid item xs={2} className={classes.edit}>
          <Button className={classes.editButton} variant="text" onClick={onEditClick}>
            Edit
          </Button>
        </Grid>
      )}
    </Grid>
    {isExpanded && (
      <Grid item container className={classes.innerContainer} direction="column" spacing={2}>
        {children}
      </Grid>
    )}
  </Grid>;
}

export default Section;
