import { set } from '..';

async function createAsset(data) {
  console.log('createAsset: ', data);
  const asset = {
    ...data,
  };
  const result = await set('assets', asset, data.id);
  console.log('result of createAsset: ', result);
  return result;
}

export default createAsset;
