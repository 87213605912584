import getAsset from '../assets/getAsset';
import { query } from '../index';

async function queryProductsByShopId(shopId) {
  try {
    console.log('queryProductsByShopId reached: ', shopId);
    const comparisons = [
      {
        prop: 'shopId',
        comparison: '==',
        value: shopId,
      },
    ];
    const items = await query('products', comparisons, { title: 'asc' });
    // Populate Categories
    const result = items ? await items.filter(({ status }) => status !== 'deleted') : [];

    console.log('Products query result: ', result);
    return result;
  } catch (err) {
    console.log('Error Get Product: ', err);
  }
}

export default queryProductsByShopId;
