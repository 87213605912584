import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './components/App/App';
import store from './store';
import { FirebaseApp, FirebaseContext } from '@castiron/castiron-firebase';
import { Theme } from "@castiron/components";

if (process.env.REACT_APP_IS_LOCAL == 'false') {
  Sentry.init({
    dsn: 'https://7616a48270f84e1498d2664c14e90a08@o732523.ingest.sentry.io/5796066',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_STAGE,
  });
}

if (process.env.REACT_APP_IS_LOCAL_FIREBASE == 'true') {
  console.log('Local Environment Detected, pointing Firebase to local emulators.');
  FirebaseApp.functions().useEmulator('localhost', 5001);
  FirebaseApp.firestore().useEmulator('localhost', 4000);
  FirebaseApp.auth().useEmulator('http://localhost:9099');
}

ReactDOM.render(
  <FirebaseContext.Provider value={FirebaseApp}>
    <Provider store={store}>
      <Theme>
        <Router>
          <Switch>
            <App />
          </Switch>
        </Router>
      </Theme>
    </Provider>
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);
