import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import { getShopsListAction, setRedirectUrl } from '../../store/reducers/shops';
import ShopTable from '../ShopTable';
import Spinner from '../Spinner';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: 17,
    },
  },
  spinnerContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 28,
    lineHeight: '36px',
    fontWeight: 800,
  },
}));

const Dashboard: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { isShopListLoading, shops, redirectUrl } = useAppSelector(state => ({
    isShopListLoading: state.shops.loading,
    shops: state.shops.shops,
    redirectUrl: state.shops.redirectUrl,
  }));

  useEffect(() => {
    // Check for redirect in query param
    if (shops && redirectUrl && history) {
      history.push(redirectUrl);
      dispatch(setRedirectUrl(''));
    }
    dispatch(getShopsListAction());
  }, [redirectUrl]);

  return (
    <>
      <Spinner show={isShopListLoading} />
      <div>
        <Helmet>
          <title>Home | Castiron Admin</title>
        </Helmet>
        <Grid className={classes.container} container>
          <Grid className={classes.container} container direction="column">
            <Grid item xs={12}>
              <Typography className={classes.title}>
                <h2>Castiron Admin Tool</h2>
                <ShopTable shopRows={shops}></ShopTable>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
