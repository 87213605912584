import { query } from '../index';
import moment from 'moment';
import getCustomer from '../customers/getCustomer';

// date range is number of days to query
async function queryTransactionsByShopId(shopId, dateRange = null, orderBy = {}) {
  try {
    console.log('queryTransactionsByShopId reached: ', shopId);
    const comparisons = [
      {
        prop: 'shopId',
        comparison: '==',
        value: shopId,
      },
      {
        prop: 'transactionStatus',
        comparison: '==',
        value: 'succeeded',
      },
    ];

    if (dateRange) {
      console.log('queryTransactionsByShopId date: ', dateRange);
      const startDateComparison = {
        prop: 'createdAt',
        comparison: '>',
        value: dateRange.startDate,
      };
      const endDateComparison = {
        prop: 'createdAt',
        comparison: '<',
        value: dateRange.endDate,
      };
      comparisons.push(startDateComparison);
      comparisons.push(endDateComparison);
    }
    console.log('queryTransactionsByShopId date comparison', comparisons);
    const items = await query('transactions', comparisons, orderBy);
    const result = items
      ? await Promise.all(
          items.map(async item => {
            item.customerObj = await getCustomer(item.customer);
            return item;
          }),
        )
      : [];
    return result;
  } catch (err) {
    console.log('Error Get Transaction: ', err);
  }
}

export default queryTransactionsByShopId;
