import React from 'react';
import ContactModal from '../ContactModal';
import ResetPasswordModal from '../ResetPasswordModal';
import NoteModal from '../NoteModal';
import SimpleAlert from '../SimpleAlert';

const MODAL_COMPONENTS = {
  CONTACT_MODAL: ContactModal,
  RESET_PASSWORD_MODAL: ResetPasswordModal,
  NOTE_MODAL: NoteModal,
  SIMPLE_ALERT: SimpleAlert,
};

type Props = {
  modalType: string;
  modalProps: object;
};

const RootModal: React.FC<Props> = (props: Props) => {
  if (!props.modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[props.modalType];

  return <SpecificModal {...props.modalProps} />;
};

export default RootModal;
