import React from 'react';
import { Grid, GridProps, makeStyles, Theme } from '@material-ui/core';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { Text } from '../';

const useStyles = makeStyles((theme: Theme) => ({
  default: {
    backgroundColor: 'RGBA(0, 189, 133, 0.1)',
    borderRadius: 4,
    width: 'fit-content',
    padding: '0px 4px',
  },
  localOfferIcon: {
    color: theme.branding.mint,
    fontSize: '14px',
    marginRight: 5,
  },
}));

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type PriceTagChipProps = Override<GridProps, {}>;

// Price tag chip
const PriceTagChip: React.FC<PriceTagChipProps> = (props: PriceTagChipProps) => {
  const { children, className, ...restOfProps } = props;

  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={`${classes.default} ${className}`} {...restOfProps}>
      <LocalOfferIcon className={classes.localOfferIcon} />
      <Text presetFont="txt--s" color="RGB(82, 82, 82)">
        {children}
      </Text>
    </Grid>
  );
};

export default PriceTagChip;
