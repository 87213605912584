import React, { ElementType } from 'react';
import { IconButton, SvgIcon, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  icon: ElementType;
  iconClassName?: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  amountBtn: {
    width: 25,
    height: 25,
    border: '1px solid #E6E6EA',
  },
  btnIcon: {
    fontSize: 17,
  },
}));

const CircleButton: React.FC<Props> = (props: Props) => {
  const { onClick, icon, iconClassName } = props;
  const classes = useStyles();

  return (
    <IconButton onFocus={(e): void => e.stopPropagation()} onClick={onClick} className={classes.amountBtn}>
      <SvgIcon component={icon} className={`${classes.btnIcon} ${iconClassName}`} />
    </IconButton>
  );
};

export default CircleButton;
