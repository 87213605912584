// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
import isNode from 'detect-node';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';

import config from './config';

let FirebaseApp;
if (!firebase.apps.length) {
   FirebaseApp = firebase.initializeApp(config);
}else {
   FirebaseApp = firebase.app(); // if already initialized, use that one
}

//const FirebaseApp = firebase.initializeApp(config);

if (!isNode) {
    // next.js initializes route logic in Node and firebase.analytics does not work in Node
    firebase.analytics();
}
export default FirebaseApp;
//export const db = firebase.firestore();
//console.log('db: ', db);
