import { DialogActions, DialogContent, IconButton, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Button } from '@castiron/components';
import Confetti from '@castiron/components/src/Confetti/Confetti';
import ModalWrapper from '../RootModal/ModalWrapper';
import { useAppDispatch } from '../../hooks';
import { closeModal } from '../../store/reducers/modalConductor';

type Props = {
  show: boolean;
  celebrate?: boolean;
  content: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      padding: 24,
    },
    paddingBottom: 12,
    paddingTop: 24,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
    zIndex: 10,
    fontSize: 32,
  },
  content: {
    fontSize: 16,
  },
}));

const SimpleAlert: React.FC<Props> = (props: Props) => {
  const { show, celebrate, content } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const initConfetti = (isShowing, isCelebrating): void => {
    if (isShowing && isCelebrating) {
      setTimeout(() => {
        new Confetti();
      }, 500);
    }
  };

  useEffect(() => {
    initConfetti(show, celebrate);
  }, [show, celebrate]);

  const handleClose = (): void => {
    dispatch(closeModal());
  };

  return (
    <ModalWrapper show={show} size="xs">
      <IconButton className={classes.closeIcon} onClick={handleClose}>
        <Close />
      </IconButton>
      <div className={classes.container}>
        <DialogContent>
          <Typography className={classes.content}>{content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            OK
          </Button>
        </DialogActions>
      </div>
    </ModalWrapper>
  );
};

export default SimpleAlert;
