import React from 'react';
import { Grid, GridProps, makeStyles, Theme } from '@material-ui/core';
import EventNoteIcon from '@material-ui/icons/EventNote';
import { Text } from '../';

const useStyles = makeStyles((theme: Theme) => ({
  default: {
    backgroundColor: 'RGBA(115, 97, 168, 0.1)',
    borderRadius: 4,
    width: 'fit-content',
    padding: '0px 4px',
  },
  eventNoteIcon: {
    color: '#7361A8',
    fontSize: '14px',
    marginRight: 5,
  },
}));

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type DateTagChipProps = Override<GridProps, {}>;

// Date tag chip
const DateTagChip: React.FC<DateTagChipProps> = (props: DateTagChipProps) => {
  const { children, className, ...restOfProps } = props;

  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={`${classes.default} ${className}`} {...restOfProps}>
      <EventNoteIcon className={classes.eventNoteIcon} />
      <Text presetFont="txt--s" color="RGB(82, 82, 82)">
        {children}
      </Text>
    </Grid>
  );
};

export default DateTagChip;
