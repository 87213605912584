import create from './createProduct';
import get from './getProduct';
import update from './updateProduct';
import queryByShopId from './queryProductsByShopId';

const api = {
  create,
  get,
  update,
  queryByShopId,
};

export default api;
