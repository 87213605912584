import FirebaseApp from '../config/initFirebase';
import getSafeUser from './getSafeUser';

async function loginWithPassword(email, password) {
  // userCredential.user
  const { user } = await FirebaseApp.auth().signInWithEmailAndPassword(email, password);
  console.log('user: ', user);
  const safeUser = getSafeUser(user);
  return safeUser;
}

export default loginWithPassword;
