import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const HomeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 18 18.5" {...props}>
    <g transform="translate(-7.000000, -7.000000)">
      <path d="M15.0910501,11.3542428 C15.616182,10.8819191 16.385277,10.8819191 16.9104089,11.3542428 L16.9104089,11.3542428 L22.9652118,16.784618 C23.294472,17.1007888 23.4876911,17.5464995 23.5,18.018089 L23.5,18.018089 L23.5,23.4484642 C23.507629,24.2960302 22.868575,24.9901986 22.0715813,25 L22.0715813,25 L18.1425248,25 C17.7439426,24.995312 17.4242992,24.6481014 17.4283499,24.2242321 L17.4283499,24.2242321 L17.4283499,19.9575087 C17.4319974,19.5338119 17.1125865,19.1868538 16.7141749,19.1817408 L16.7141749,19.1817408 L15.2858251,19.1817408 C14.8874135,19.1868538 14.5680026,19.5338119 14.5716501,19.9575087 L14.5716501,19.9575087 L14.5716501,24.2242321 C14.5757008,24.6481014 14.2560574,24.995312 13.8574752,25 L13.8574752,25 L9.92841871,25 C9.13142504,24.9901986 8.49237096,24.2960302 8.5,23.4484642 L8.5,23.4484642 L8.5,18.018089 C8.51378332,17.5478771 8.70745843,17.1040115 9.03624715,16.7892726 L9.03624715,16.7892726 Z M14.3463739,7.56697794 C15.3055129,6.81100735 16.6906566,6.81100735 17.6497956,7.56697794 L17.6497956,7.56697794 L24.5822312,13.0468185 C25.0661479,13.4341245 25.1401436,14.1190528 24.7492409,14.592715 C24.5645254,14.8185643 24.2920142,14.9632985 23.9938238,14.9939269 C23.6956333,15.0245552 23.3971749,14.9384676 23.1664791,14.7552868 L23.1664791,14.7552868 L16.2340434,9.2754462 C16.0971483,9.16711882 15.8990212,9.16711882 15.7621261,9.2754462 L15.7621261,9.2754462 L8.82969042,14.7552868 C8.59903759,14.9368991 8.30156441,15.0220221 8.00445499,14.9914308 C7.70734557,14.9608394 7.43566028,14.8171141 7.25075906,14.592715 C6.85985642,14.1190528 6.93385212,13.4341245 7.41776878,13.0468185 L7.41776878,13.0468185 Z" />
    </g>
  </SvgIcon>
);
