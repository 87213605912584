import React from "react";
import { Typography as MuiTypography, TypographyProps } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

/**
 * So adding custom variants does not seem to be fully supported in MUI 4. Upgrading to MUI 5 is out of scope right
 * now, so we'll do a little work around. This Typography component basically wraps the MUI one, adding in the
 * additional variants we want. The actual CSS for the new variants IS in the theme, it's just adding and connecting
 * those new variants to the Typography component that is the issue. So, for each of the new variants we define
 * a base, existing variant it extends, then manually add the styling from the theme.
 */

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'subtitle3'
  | 'subtitle4'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'placeholder1'
  | 'placeholder2'
  | 'caption'
  | 'caption2'
  | 'caption3'
  | 'caption4'
  | 'button'
  | 'button2'
  | 'overline';

export type Props = Override<TypographyProps,
  {
    variant?: Variant,
    component?: React.ElementType,
  }>;


const extendedVariants = {
  'body3': 'body1',
  'body4': 'body1',
  'subtitle3': 'subtitle1',
  'subtitle4': 'subtitle1',
  'placeholder1': 'body1',
  'placeholder2': 'body1',
  'caption2': 'caption',
  'caption3': 'caption',
  'caption4': 'caption',
  'button2': 'button'
}

const useStyles = makeStyles((theme: Theme) =>
  Object.fromEntries(Object.keys(extendedVariants).map(variant => [variant, theme.typography[variant]]))
);


const Typography: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  let variant;
  let className;
  if (extendedVariants[props.variant]) {
    variant = extendedVariants[props.variant];
    className = clsx([classes[props.variant], props.className]);
  } else {
    variant = props.variant;
    className = props.className;
  }

  //@ts-ignore
  return <MuiTypography { ...props } variant={ variant } className={ className }>{ props.children }</MuiTypography>;
}

export default Typography;
