let config;

const {
  NEXT_PUBLIC_IS_NEXT,
  NEXT_PUBLIC_FIREBASE_API_KEY,
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  NEXT_PUBLIC_FIREBASE_APP_ID,
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env;

const isNext = process.env.NEXT_PUBLIC_IS_NEXT === 'true';

// Firebase Config
// Next.js  or Create React App env variables
config = {
  apiKey: isNext ? process.env.NEXT_PUBLIC_FIREBASE_API_KEY : process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: isNext ? process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID : process.env.REACT_APP_FIREBASE_PROJECT_ID,
  //databaseURL: "DATABASE_URL",
  authDomain: isNext ? process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN : process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // OPTIONAL
  storageBucket: isNext ? process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET : process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: isNext ? process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID : process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: isNext ? process.env.NEXT_PUBLIC_FIREBASE_APP_ID : process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: isNext ? process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export default config;
